.checkout-form {
  overflow: auto;
  margin-top: 3em;
  position: relative; }
  .checkout-form div {
    margin-bottom: 0; }
  .checkout-form .empty {
    display: none; }
  @media (min-width: 768px) {
    .checkout-form .info {
      width: 43%;
      float: left;
      border-right: 1px solid #ccc;
      padding-right: 5%; } }
  .checkout-form .error {
    text-align: right;
    float: right;
    clear: right;
    margin-top: 1em;
    color: #fff;
    background: red;
    display: inline-block;
    padding: 0.25em .5em; }
  .checkout-form .group-label {
    margin: 2em 0 1em;
    font-size: 1em;
    font-weight: bold; }
    .checkout-form .group-label:first-child {
      margin-top: 0; }
    .checkout-form .group-label .svg-inline--fa {
      margin-right: 1em; }
  .checkout-form .label {
    display: block;
    width: 7em;
    float: left;
    text-align: left;
    margin: -.1em 1em 0 0;
    font-size: 0.8em; }
  .checkout-form label {
    width: 7em; }
  .checkout-form .input-row {
    margin: 0;
    padding-bottom: .5em; }
    @media (min-width: 768px) {
      .checkout-form .input-row.number .label {
        width: 7em;
        margin-right: .5em; } }
    .checkout-form .input-row.expiry {
      float: left;
      width: 9em; }
      @media (min-width: 768px) {
        .checkout-form .input-row.expiry {
          width: 15em; } }
      .checkout-form .input-row.expiry .label {
        width: 4em;
        margin-right: .25em; }
        @media (min-width: 768px) {
          .checkout-form .input-row.expiry .label {
            width: 5em;
            margin-right: .5em; } }
    .checkout-form .input-row.cvc {
      float: left;
      width: 7em; }
      @media (min-width: 768px) {
        .checkout-form .input-row.cvc {
          width: 9em; } }
      .checkout-form .input-row.cvc .label {
        width: 2em;
        margin-right: .25em; }
        @media (min-width: 768px) {
          .checkout-form .input-row.cvc .label {
            width: 3em; } }
  .checkout-form input {
    width: 10em; }
    @media (min-width: 768px) {
      .checkout-form input {
        width: calc(100% - 7em); } }
    @media (min-width: 768px) {
      .checkout-form input[name="suburb"] {
        width: 8em; } }
    @media (min-width: 768px) {
      .checkout-form input[name="zip"] {
        width: 4em; } }
  .checkout-form .card-input {
    width: 100%; }
    @media (min-width: 768px) {
      .checkout-form .card-input {
        padding: .5em 0; } }
  .checkout-form .button {
    clear: none;
    display: block;
    margin-top: 2em;
    text-align: center; }
    @media (min-width: 768px) {
      .checkout-form .button {
        clear: right;
        float: right;
        display: inline-block;
        width: 9.25em; } }
    .checkout-form .button.inactive {
      cursor: not-allowed;
      border: 1px solid #ccc; }
    .checkout-form .button.sending {
      cursor: not-allowed;
      color: #fff;
      background: green;
      border: 1px solid green;
      font-size: 1em;
      padding: .5em 0;
      width: 13em; }

.StripeElement {
  padding-top: .5em;
  display: inline-block;
  border-bottom: 1px solid #1f1f1f;
  width: 50%;
  padding: .1em .25em; }
  .invalid .StripeElement {
    border-bottom-color: red; }

.stripe-error {
  text-align: right;
  margin-top: 1em;
  color: #fff;
  background: red;
  display: inline-block;
  padding: 0.25em .5em; }
  @media (min-width: 768px) {
    .stripe-error {
      float: right;
      max-width: 40%; } }
  .stripe-error .svg-inline--fa {
    margin-right: .5em; }

.successful-payment span {
  margin-right: .5em; }
  .successful-payment span.number, .successful-payment span.plans {
    border: 1px solid #ccc;
    padding: .25em;
    border-radius: .2em;
    background: rgba(255, 165, 0, 0.25);
    font-size: 0.8em; }

.successful-payment .button {
  margin-top: 1em; }
