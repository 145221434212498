.address .locations {
  margin: 1rem 0; }
  .location .address .locations {
    margin-bottom: 2em; }
    .location .address .locations h2 {
      display: none; }
    .location .address .locations h3 {
      margin-top: 0; }
  .address .locations div[itemprop="address"] {
    margin-top: 1em; }
  .address .locations [itemprop="streetAddress"] {
    display: block; }
  .address .locations [itemprop="postalCode"] {
    margin-right: .5em; }

.address span {
  display: inline-block;
  clear: left; }
  .address span[itemprop="telephone"] a {
    margin-left: .5em; }
