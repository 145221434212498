.home .interest-form > div {
  margin-bottom: 1em; }

.page.landing .interest-form {
  clear: both;
  margin: 2em 0 0; }

.interest-form h2 {
  text-align: center; }

.page.landing .interest-form .button-container.claim {
  margin-bottom: 0; }

.interest-form .button-container.claim .button {
  width: calc(100% - 4em);
  text-align: center; }
  @media (min-width: 768px) {
    .interest-form .button-container.claim .button {
      width: auto; } }
  .page.landing .interest-form .button-container.claim .button {
    margin-bottom: 0; }

.interest-form form {
  background: #fff;
  max-width: 100%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .interest-form form {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    .interest-form form {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    .interest-form form {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    .interest-form form {
      text-align: left;
      margin: 0 auto;
      width: 50%; }
      .sidebar .interest-form form {
        width: 100%; } }
  .interest-form form button {
    font-size: 1.25em; }

.interest-form label {
  width: 4em;
  font-size: 1em;
  text-align: left; }

.interest-form input {
  width: 90%; }
  @media (min-width: 768px) {
    .interest-form input {
      width: calc(90% - 4em); } }

.interest-form .textarea {
  display: none; }

@media (min-width: 768px) {
  .interest-form .child-name label {
    width: 6em; } }

@media (min-width: 768px) {
  .interest-form .child-name input {
    width: 13em; } }

@media (min-width: 768px) {
  .interest-form textarea {
    width: 90%; } }
