@import '../../base/colors';
@import '../../base/mixins';
@import '../../base/typography';

img {
  max-width: 100%;

  &.full-width {
    display: block;
    @include container(false);
  }
}
