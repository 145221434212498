.faq {
  position: relative;
  max-width: 42em;
  border-bottom: solid 1px #ccc;
  clear: both;
  overflow: auto; }
  @media (min-width: 768px) {
    .faq {
      width: 42em; } }
  .faq .heading {
    cursor: pointer; }
    .faq .heading .svg-inline--fa {
      margin-right: .5em;
      font-size: 1.25em;
      position: absolute; }
    .faq .heading h2 {
      margin-left: 1.5em; }
  @media (min-width: 768px) {
    .faq .content {
      margin-left: 2em; } }
  .faq .content.show {
    display: block; }
  .faq .content.hide {
    display: none; }
