.ClassesUpcoming {
  width: 90%; }
  .ClassesUpcoming .title {
    font-weight: bold;
    cursor: pointer;
    margin-bottom: .5rem; }
    .ClassesUpcoming .title:focus {
      outline: none;
      text-decoration: underline; }
    .ClassesUpcoming .title.open {
      margin-bottom: 1rem; }
    .ClassesUpcoming .title .svg-inline--fa {
      position: relative;
      left: unset;
      top: unset;
      margin-left: .5rem; }
    .ClassesUpcoming .title .number {
      display: inline-block;
      background: orange;
      padding: 0 .525rem;
      color: #fff;
      font-weight: 700;
      margin-left: .25rem;
      border: 1px solid orange; }
    .ClassesUpcoming .title:hover {
      text-decoration: underline; }
      .ClassesUpcoming .title:hover .number {
        background: #fff;
        color: orange; }
  .ClassesUpcoming .next-classes {
    display: none;
    grid-template-columns: 6rem 6rem;
    row-gap: 1rem;
    column-gap: 1rem; }
    .ClassesUpcoming .next-classes.show {
      display: grid; }
    .ClassesUpcoming .next-classes div {
      margin-bottom: 0; }
    .ClassesUpcoming .next-classes .mon,
    .ClassesUpcoming .next-classes .tue,
    .ClassesUpcoming .next-classes .sat {
      grid-column: 1; }
    .ClassesUpcoming .next-classes .wed {
      grid-column: 2; }
  .ClassesUpcoming.childrens-aikido .wed {
    grid-column: 1; }
