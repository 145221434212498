.HomeFeature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  padding-bottom: 10rem; }
  .HomeFeature br {
    display: none; }
    @media (min-width: 768px) {
      .HomeFeature br {
        display: inline-block; } }
  .HomeFeature > a {
    width: 100%;
    text-decoration: none; }
  .HomeFeature h1 {
    text-align: center;
    font-size: 1.5rem;
    padding-top: 1rem; }
    @media (min-width: 768px) {
      .HomeFeature h1 {
        font-size: 2rem; } }
  .HomeFeature img {
    width: 100%; }
  .HomeFeature .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (min-width: 768px) {
      .HomeFeature .actions {
        flex-direction: row; } }
    .HomeFeature .actions .svg-inline--fa {
      margin-right: .5rem; }
    .HomeFeature .actions .secondary {
      margin: 1rem 0 0 0; }
      @media (min-width: 768px) {
        .HomeFeature .actions .secondary {
          margin: 0 0 0 3rem; } }
