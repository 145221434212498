.error-container {
  font-size: 1.5em;
  display: block;
  text-align: center;
  max-width: 42em;
  padding: 1em 0;
  position: relative;
  background: rgba(255, 0, 0, 0.2); }
  .error-container .fa-exclamation-triangle {
    margin-right: .5em;
    display: inline;
    color: red; }
  form .error-container {
    border: red;
    margin: 1rem 0;
    font-size: 1rem; }
    @media (min-width: 768px) {
      form .error-container {
        grid-row: 2;
        grid-column: 2; } }
    form .error-container:before {
      content: "";
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
      left: 45%;
      top: -23px;
      border-bottom: 10px solid rgba(255, 0, 0, 0.2); }
