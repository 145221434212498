.success {
  font-size: 1.5em;
  display: block;
  text-align: center;
  max-width: 42em;
  padding: 1em 0;
  position: relative; }
  .success .svg-inline--fa {
    margin-right: .5em;
    color: green; }
