@media (min-width: 768px) {
  .field.textarea {
    display: grid;
    grid-template-columns: 10rem 30rem;
    grid-template-rows: 11rem; } }

.field.textarea textarea {
  font-size: 1em;
  height: 5em;
  width: 90%;
  vertical-align: top;
  border: 1px solid #262262;
  padding: .5em; }
  @media (min-width: 768px) {
    .field.textarea textarea {
      grid-row: 1;
      grid-column: 2;
      height: 10rem;
      width: 96%; } }
  .field.textarea textarea.invalid {
    border-color: red; }
