form {
  font-size: 1em; }
  @media (min-width: 768px) {
    form {
      max-width: 42em; } }
  form.hidden {
    display: none; }
  form > div {
    margin-bottom: 1em; }
    form > div:last-child {
      margin-bottom: 0; }
  form .honeypot {
    display: none; }
  form .hidden {
    display: none; }

.invalid {
  border-bottom-color: red; }

.valid {
  border-bottom-color: green; }
