.alert {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  background: orange;
  padding: 1rem 3rem 1rem 2rem;
  font-size: .8rem;
  z-index: 0;
  text-align: center; }
  @media (min-width: 768px) {
    .alert {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    .alert {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    .alert {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    .alert {
      padding: 1em;
      font-size: 1rem; } }
  .alert a {
    color: #fff;
    text-decoration: none; }
    .alert a:hover {
      text-decoration: underline; }
  .alert br {
    display: none; }
    @media (min-width: 768px) {
      .alert br {
        display: inline-block; } }
  .alert .svg-inline--fa {
    margin-right: 1em;
    left: .5em;
    display: inline-block; }
  .alert .close {
    position: absolute;
    right: 0;
    top: 1.5em;
    font-size: 1.2em; }
    @media (min-width: 768px) {
      .alert .close {
        right: .8em;
        top: .8em; } }
  .alert .button {
    font-size: .8rem;
    padding: .25rem .5rem;
    margin-left: 1rem;
    background: #fff;
    color: orange;
    border: 1px solid #fff; }
    .alert .button:visited {
      color: orange; }
    .alert .button:hover {
      background: orange;
      color: #fff;
      border-color: #fff; }
