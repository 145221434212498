.Shop p.description {
  margin-bottom: 2rem; }

.Shop .products {
  border-top: 1px solid #ccc;
  padding-top: 1rem; }

.Shop ul.tags {
  margin: 0 auto 1em;
  display: table;
  font-size: 0.8em;
  list-style-type: none;
  margin-bottom: 1em;
  overflow: auto;
  max-width: 100% !important; }
  .Shop ul.tags li {
    float: left; }
    .Shop ul.tags li:first-child {
      margin-left: 0; }
    .Shop ul.tags li button {
      padding: .25em 1em;
      text-transform: uppercase;
      border: 1px solid #262262;
      margin-right: .5em; }
      .Shop ul.tags li button:focus, .Shop ul.tags li button:hover {
        outline: none;
        border-color: orange;
        background: orange;
        color: #fff; }
      .Shop ul.tags li button.selected {
        background: #262262;
        color: #fff; }
        .Shop ul.tags li button.selected:focus, .Shop ul.tags li button.selected:hover {
          background: orange;
          border-color: orange;
          color: #fff; }
      .Shop ul.tags li button.clear-tags {
        border: none; }
