$max-width: 42em;

@mixin breakpoint($class) {
  @if $class == xs {
    @media (min-width: 375px) { @content; }
  }
  @if $class == sm {
    @media (min-width: 768px){ @content; }
  }
  @else if $class == md {
    @media (min-width: 1000px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == xl {
    @media (min-width: 1500px) { @content; }
  }
  
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
  }
}

@mixin container($padding: true) {
  background: $white;
  max-width: 100%;
  margin: 0 auto;    

  @if $padding == true {
    @include breakpoint(sm) {
      max-width: 768px;
    }
  
    @include breakpoint(md) {
      max-width: 950px;
    }
  
    @include breakpoint(lg) {
      max-width: 1000px;
    }
  }
  @else {
    @include breakpoint(sm) {
      max-width: 800px;
    }
  
    @include breakpoint(md) {
      max-width: 981px;
    }
  
    @include breakpoint(lg) {
      max-width: 1032px;
    }
  }
}