.checkout {
  position: relative; }
  .checkout .notice {
    font-size: 0.8em;
    color: #333;
    text-align: center; }
    @media (min-width: 768px) {
      .checkout .notice {
        position: absolute;
        bottom: 0;
        width: calc(100% - 4em); } }
    .checkout .notice .fa-lock {
      color: green; }
