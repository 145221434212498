.CalendarDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #262262;
  border: 1px solid #262262;
  font-size: .8rem; }
  .CalendarDate div {
    padding: .5rem;
    width: calc(100% - 1rem);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .CalendarDate .time {
    background: #262262;
    color: #fff;
    padding: .25rem .5rem;
    font-weight: 700;
    line-height: 1rem; }
  .CalendarDate .day {
    padding: .25rem 0 0 0;
    text-transform: uppercase;
    line-height: 1rem; }
  .CalendarDate .date {
    font-size: 3rem;
    font-weight: 700;
    height: 3rem;
    padding-top: 0; }
  .CalendarDate .month {
    text-transform: uppercase;
    background: #262262;
    color: #fff;
    padding: .25rem .5rem;
    font-weight: 700;
    line-height: 1rem; }
