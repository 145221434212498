.dan-rank-row {
  border-bottom: 1px solid #ccc;
  padding: .5em 0;
  margin-bottom: 0; }
  .dan-rank-row:last-child {
    border-bottom: none; }
  @media (min-width: 768px) {
    .dan-rank-row {
      display: grid;
      grid-template-columns: 25% 1fr; }
      .dan-rank-row.grade {
        grid-template-columns: 25% 1fr 15em; } }
  @media print {
    .dan-rank-row {
      display: grid;
      grid-template-columns: 30% 1fr; } }
  .dan-rank-row div {
    margin-bottom: 0; }
  .dan-rank-row .label {
    grid-column: 1;
    font-size: 1em;
    margin-bottom: 0; }
  .dan-rank-row .content {
    grid-column: 2; }
  .dan-rank-row .attacks,
  .dan-rank-row .variations {
    margin-bottom: .25em;
    display: grid;
    grid-template-columns: 5.5em 1fr; }
    .dan-rank-row .attacks .label-a,
    .dan-rank-row .variations .label-a {
      font-size: 1em;
      grid-column: 1; }
      .dan-rank-row .attacks .label-a:after,
      .dan-rank-row .variations .label-a:after {
        content: ":"; }
    .dan-rank-row .attacks .content,
    .dan-rank-row .variations .content {
      grid-column: 2; }
      @media (min-width: 1000px) {
        .dan-rank-row .attacks .content,
        .dan-rank-row .variations .content {
          padding-right: .5em; } }
