@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400|Zilla+Slab+Highlight:700|Overpass+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#Logo {
  fill: #262262; }
  #Logo:hover, #Logo:active {
    fill: orange; }

header {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 1em 1em 0;
  height: 7.5em; }
  @media (min-width: 768px) {
    header {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    header {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    header {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    header {
      display: grid;
      grid-template-columns: 18em 1fr 18em;
      grid-template-rows: 1fr 1.5rem;
      margin-bottom: 0;
      height: auto; } }
  @media print {
    header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 0;
      display: block;
      clear: both;
      overflow: auto;
      height: 5em;
      padding: 1em 2em 0;
      max-width: 970px; } }
  header a {
    display: block;
    border: 1px solid #fff; }
    header a:hover, header a:focus {
      outline: none; }
    header a svg {
      max-width: 13em;
      display: block;
      margin: auto; }
      @media (min-width: 768px) {
        header a svg {
          max-width: 18em;
          margin: 0;
          grid-row: 1;
          grid-column: 1; } }
      @media print {
        header a svg {
          max-width: 13em;
          float: left; } }
  header .tagline {
    font-family: 'Zilla Slab Highlight', sans-serif;
    text-transform: uppercase;
    color: orange;
    text-align: center;
    font-size: 1.35rem; }
    @media (min-width: 768px) {
      header .tagline {
        font-size: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0; } }
    @media print {
      header .tagline {
        float: right;
        clear: right;
        font-size: 1.5em;
        margin-top: .5em;
        color: #1f1f1f; } }
  header .location {
    font-family: 'Overpass Mono', monospace;
    text-transform: uppercase;
    font-size: 0.8em;
    text-align: center; }
    @media (min-width: 768px) {
      header .location {
        grid-row: 2;
        grid-column: 1; } }

.alert {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  background: orange;
  padding: 1rem 3rem 1rem 2rem;
  font-size: .8rem;
  z-index: 0;
  text-align: center; }
  @media (min-width: 768px) {
    .alert {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    .alert {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    .alert {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    .alert {
      padding: 1em;
      font-size: 1rem; } }
  .alert a {
    color: #fff;
    text-decoration: none; }
    .alert a:hover {
      text-decoration: underline; }
  .alert br {
    display: none; }
    @media (min-width: 768px) {
      .alert br {
        display: inline-block; } }
  .alert .svg-inline--fa {
    margin-right: 1em;
    left: .5em;
    display: inline-block; }
  .alert .close {
    position: absolute;
    right: 0;
    top: 1.5em;
    font-size: 1.2em; }
    @media (min-width: 768px) {
      .alert .close {
        right: .8em;
        top: .8em; } }
  .alert .button {
    font-size: .8rem;
    padding: .25rem .5rem;
    margin-left: 1rem;
    background: #fff;
    color: orange;
    border: 1px solid #fff; }
    .alert .button:visited {
      color: orange; }
    .alert .button:hover {
      background: orange;
      color: #fff;
      border-color: #fff; }

nav {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  z-index: 99; }
  @media (min-width: 768px) {
    nav {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    nav {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    nav {
      max-width: 1000px; } }
  @media (min-width: 1000px) {
    nav {
      max-width: 798px;
      height: 2.1em;
      background: #fff;
      position: relative;
      overflow: visible;
      margin: 0 auto; } }
  @media (min-width: 1000px) {
    nav {
      max-width: 982px; } }
  @media (min-width: 1200px) {
    nav {
      max-width: 1030px; } }
  @media print {
    nav {
      display: none; } }
  nav .toggle {
    font-size: 1.25em;
    display: block;
    position: absolute;
    top: 1.4em;
    left: 0;
    z-index: 99;
    color: #262262;
    padding: .5em 1em 1em .5em; }
    @media (min-width: 1000px) {
      nav .toggle {
        display: none; } }
  nav .fa-times {
    margin: -.75em 0 0 .5em;
    color: #fff; }
  nav ul {
    list-style-type: none;
    background: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    width: 100vw;
    height: 300vh;
    margin: 0;
    padding: 3em 0;
    z-index: 50; }
    @media (min-width: 1000px) {
      nav ul {
        position: absolute;
        background: #fff;
        padding: 0;
        height: auto;
        width: 100%;
        overflow: visible; } }
    nav ul.hide {
      display: none; }
      @media (min-width: 1000px) {
        nav ul.hide {
          display: block; } }
    nav ul.show {
      display: block; }
    nav ul li {
      border-bottom: 1px solid #333;
      position: relative; }
      nav ul li a {
        display: block;
        color: #fff;
        text-decoration: none;
        padding: 1em;
        font-weight: bold;
        width: calc(100% - 2em); }
        nav ul li a:visited {
          color: #fff; }
          @media (min-width: 1000px) {
            nav ul li a:visited {
              color: #1f1f1f; } }
        nav ul li a.current {
          color: #262262;
          background: white; }
          @media (min-width: 1000px) {
            nav ul li a.current {
              background: #262262;
              color: white; } }
          nav ul li a.current > ul {
            display: block; }
        @media (min-width: 1000px) {
          nav ul li a {
            color: #1f1f1f;
            padding: .5em 1em; } }
      @media (min-width: 1000px) {
        nav ul li:hover > a {
          background: #262262;
          color: white; } }
      @media (min-width: 1000px) {
        nav ul li:hover ul {
          display: block; }
          nav ul li:hover ul.expand {
            display: block; } }
      nav ul li.flag a img {
        width: 1.5em;
        padding-bottom: 0; }
      nav ul li.flag a:hover {
        background: transparent; }
        nav ul li.flag a:hover img {
          opacity: 0.8; }
      @media (min-width: 768px) {
        nav ul li.parent > a {
          padding: .5em 1em .5em 1.5em; } }
      @media (min-width: 1000px) {
        nav ul li {
          float: left;
          border-bottom: none; } }
      nav ul li ul {
        height: auto;
        padding: 0;
        display: none; }
        nav ul li ul.expand {
          display: block; }
          @media (min-width: 1000px) {
            nav ul li ul.expand {
              display: none; } }
        @media (min-width: 1000px) {
          nav ul li ul {
            position: absolute;
            top: 2.3rem;
            z-index: 199;
            min-width: 15em;
            border: 1px solid #262262; } }
        @media (min-width: 1000px) {
          nav ul li ul > li {
            width: 100%; } }
        nav ul li ul > li a {
          padding-left: 2em; }
          @media (min-width: 1000px) {
            nav ul li ul > li a {
              padding-left: 1em; } }

.chevron {
  color: #fff;
  float: left;
  margin: 1em .5em 0 .5em;
  font-size: 1em; }
  .current .chevron,
  .active .chevron {
    color: #262262; }
    @media (min-width: 1000px) {
      .current .chevron,
      .active .chevron {
        color: #fff; } }
  .parent .chevron.down {
    display: none; }
    @media (min-width: 768px) {
      .parent .chevron.down {
        display: block; } }
  .parent .chevron.right {
    float: left; }
    @media (min-width: 768px) {
      .parent .chevron.right {
        display: none; } }
  .active .chevron.right {
    display: none; }
  .expand .chevron.right {
    display: none; }
  .active .chevron.down {
    display: block; }
  .expand .chevron.down {
    display: block;
    color: #fff; }
  .active.expand .chevron.down {
    display: block;
    color: #262262; }
  @media (min-width: 768px) {
    .chevron {
      color: #262262;
      margin-top: .6em; }
      li:hover .chevron {
        color: #fff; } }

.HomeFeature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  padding-bottom: 10rem; }
  .HomeFeature br {
    display: none; }
    @media (min-width: 768px) {
      .HomeFeature br {
        display: inline-block; } }
  .HomeFeature > a {
    width: 100%;
    text-decoration: none; }
  .HomeFeature h1 {
    text-align: center;
    font-size: 1.5rem;
    padding-top: 1rem; }
    @media (min-width: 768px) {
      .HomeFeature h1 {
        font-size: 2rem; } }
  .HomeFeature img {
    width: 100%; }
  .HomeFeature .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    @media (min-width: 768px) {
      .HomeFeature .actions {
        flex-direction: row; } }
    .HomeFeature .actions .svg-inline--fa {
      margin-right: .5rem; }
    .HomeFeature .actions .secondary {
      margin: 1rem 0 0 0; }
      @media (min-width: 768px) {
        .HomeFeature .actions .secondary {
          margin: 0 0 0 3rem; } }

.home {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  padding: 0; }
  @media (min-width: 768px) {
    .home {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    .home {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    .home {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    .home {
      max-width: 798px; } }
  @media (min-width: 1000px) {
    .home {
      max-width: 982px; } }
  @media (min-width: 1200px) {
    .home {
      max-width: 1030px; } }

.image-heading {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
  height: 100px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .image-heading {
      max-width: 800px; } }
  @media (min-width: 1000px) {
    .image-heading {
      max-width: 981px; } }
  @media (min-width: 1200px) {
    .image-heading {
      max-width: 1032px; } }
  @media (min-width: 768px) {
    .image-heading {
      height: 200px; } }
  @media (min-width: 1000px) {
    .image-heading {
      height: 300px; } }
  @media print {
    .image-heading {
      height: 3em;
      background: #fff; } }
  @media (min-width: 1000px) {
    .image-heading h2:first-of-type {
      margin-top: -.1em; } }
  .image-heading .background-image {
    position: absolute;
    height: 100%;
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media (min-width: 768px) {
      .image-heading .background-image {
        background-position: center center; } }
    @media print {
      .image-heading .background-image {
        display: none; } }

.section-heading {
  background: #fff; }
  @media (min-width: 768px) {
    .section-heading {
      background-color: rgba(38, 34, 98, 0.8);
      position: absolute;
      margin: 0 0 0 32px;
      bottom: -.1em;
      padding: 0;
      overflow: hidden; } }
  @media print {
    .section-heading {
      background: #fff;
      height: 3em;
      margin: 0;
      bottom: 0; } }
  .section-heading h1,
  .section-heading h2 {
    font-family: 'Zilla Slab Highlight', sans-serif;
    color: #262262;
    text-align: center;
    background: transparent;
    text-transform: uppercase;
    font-size: 2.25em;
    width: 100%;
    margin: -.05em 0;
    padding: 0; }
    @media (min-width: 768px) {
      .section-heading h1,
      .section-heading h2 {
        font-size: 3em;
        color: #fff;
        text-align: left; } }
    @media (min-width: 1000px) {
      .section-heading h1,
      .section-heading h2 {
        font-size: 6em; } }
    @media print {
      .section-heading h1,
      .section-heading h2 {
        font-family: 'Open Sans', sans-serif;
        font-size: 2em;
        color: #1f1f1f;
        padding-left: 1em; } }

img {
  max-width: 100%; }
  img.full-width {
    display: block;
    background: #fff;
    max-width: 100%;
    margin: 0 auto; }
    @media (min-width: 768px) {
      img.full-width {
        max-width: 800px; } }
    @media (min-width: 1000px) {
      img.full-width {
        max-width: 981px; } }
    @media (min-width: 1200px) {
      img.full-width {
        max-width: 1032px; } }

.CalendarDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #262262;
  border: 1px solid #262262;
  font-size: .8rem; }
  .CalendarDate div {
    padding: .5rem;
    width: calc(100% - 1rem);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .CalendarDate .time {
    background: #262262;
    color: #fff;
    padding: .25rem .5rem;
    font-weight: 700;
    line-height: 1rem; }
  .CalendarDate .day {
    padding: .25rem 0 0 0;
    text-transform: uppercase;
    line-height: 1rem; }
  .CalendarDate .date {
    font-size: 3rem;
    font-weight: 700;
    height: 3rem;
    padding-top: 0; }
  .CalendarDate .month {
    text-transform: uppercase;
    background: #262262;
    color: #fff;
    padding: .25rem .5rem;
    font-weight: 700;
    line-height: 1rem; }

.ClassesUpcoming {
  width: 90%; }
  .ClassesUpcoming .title {
    font-weight: bold;
    cursor: pointer;
    margin-bottom: .5rem; }
    .ClassesUpcoming .title:focus {
      outline: none;
      text-decoration: underline; }
    .ClassesUpcoming .title.open {
      margin-bottom: 1rem; }
    .ClassesUpcoming .title .svg-inline--fa {
      position: relative;
      left: unset;
      top: unset;
      margin-left: .5rem; }
    .ClassesUpcoming .title .number {
      display: inline-block;
      background: orange;
      padding: 0 .525rem;
      color: #fff;
      font-weight: 700;
      margin-left: .25rem;
      border: 1px solid orange; }
    .ClassesUpcoming .title:hover {
      text-decoration: underline; }
      .ClassesUpcoming .title:hover .number {
        background: #fff;
        color: orange; }
  .ClassesUpcoming .next-classes {
    display: none;
    grid-template-columns: 6rem 6rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem; }
    .ClassesUpcoming .next-classes.show {
      display: grid; }
    .ClassesUpcoming .next-classes div {
      margin-bottom: 0; }
    .ClassesUpcoming .next-classes .mon,
    .ClassesUpcoming .next-classes .tue,
    .ClassesUpcoming .next-classes .sat {
      grid-column: 1; }
    .ClassesUpcoming .next-classes .wed {
      grid-column: 2; }
  .ClassesUpcoming.childrens-aikido .wed {
    grid-column: 1; }

.class-details {
  background-size: cover;
  overflow: hidden;
  margin-bottom: 1em;
  clear: both; }
  footer .class-details {
    background: transparent; }
  .class-details img {
    width: 100%;
    margin-bottom: 1rem; }
  .class-details a {
    background: transparent;
    text-decoration: underline;
    color: orange; }
    .class-details a:visited {
      background: transparent;
      text-decoration: underline;
      color: orange; }
    .class-details a:hover {
      background: orange;
      color: #fff;
      text-decoration: none; }
  .class-details > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    max-width: unset;
    width: 100%; }
    .class-details > ul li {
      margin: 0 0 1em 1.5em;
      padding: 0;
      position: relative; }
      .class-details > ul li .svg-inline--fa {
        position: absolute;
        left: -1.5em;
        top: .25em;
        color: #262262; }
      .class-details > ul li span {
        margin-left: 0; }
      .class-details > ul li.days ul {
        margin: 0;
        padding: 0;
        list-style-type: none; }
        .class-details > ul li.days ul li {
          margin: 0 0 .5rem;
          padding: 0; }
      .class-details > ul li.content ul li {
        margin-bottom: .5rem; }
      .class-details > ul li.class-name {
        margin-bottom: 1em;
        margin-left: 0; }
        .class-details > ul li.class-name h2 {
          margin: 0 0 .5em; }
        .class-details > ul li.class-name span {
          font-size: 1.25em; }

@media (min-width: 768px) {
  .Classes .heading {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

@media (min-width: 768px) {
  .Classes .heading h2 {
    margin-bottom: 0; } }

.Classes .content {
  margin-bottom: 2em; }

.Classes .fa-calendar-check {
  color: green; }

.Classes .class-name {
  font-weight: bold; }

.Classes .name {
  font-weight: bold;
  width: 6em;
  display: block;
  float: left; }

label {
  font-size: 1em;
  width: 10em;
  display: inline-block;
  padding: 0.5em 0; }
  @media (min-width: 768px) {
    label {
      grid-row: 1;
      grid-column: 1; } }
  .interest-form label {
    font-size: 1.25em;
    width: 4em; }
  .radio-button label {
    display: block;
    float: left;
    width: auto; }

.required {
  padding-left: 0.25em;
  color: red; }

.error-container {
  font-size: 1.5em;
  display: block;
  text-align: center;
  max-width: 42em;
  padding: 1em 0;
  position: relative;
  background: rgba(255, 0, 0, 0.2); }
  .error-container .fa-exclamation-triangle {
    margin-right: .5em;
    display: inline;
    color: red; }
  form .error-container {
    border: red;
    margin: 1rem 0;
    font-size: 1rem; }
    @media (min-width: 768px) {
      form .error-container {
        grid-row: 2;
        grid-column: 2; } }
    form .error-container:before {
      content: "";
      width: 0px;
      height: 0px;
      border: 0.8em solid transparent;
      position: absolute;
      left: 45%;
      top: -23px;
      border-bottom: 10px solid rgba(255, 0, 0, 0.2); }

@media (min-width: 768px) {
  .field {
    display: grid;
    grid-template-columns: 10rem 30rem; } }

.field input {
  font-size: 1em;
  padding: 0.5em;
  border: none;
  border-bottom: 1px solid #262262; }
  .field input.invalid {
    border-color: red; }
  @media (min-width: 768px) {
    .field input {
      grid-row: 1;
      grid-column: 2; } }

@media (min-width: 768px) {
  .field.textarea {
    display: grid;
    grid-template-columns: 10rem 30rem;
    grid-template-rows: 11rem; } }

.field.textarea textarea {
  font-size: 1em;
  height: 5em;
  width: 90%;
  vertical-align: top;
  border: 1px solid #262262;
  padding: .5em; }
  @media (min-width: 768px) {
    .field.textarea textarea {
      grid-row: 1;
      grid-column: 2;
      height: 10rem;
      width: 96%; } }
  .field.textarea textarea.invalid {
    border-color: red; }

.radio-buttons input {
  display: none; }

.radio-buttons label {
  margin-left: .5em; }
  .interest-form .radio-buttons label {
    width: auto; }

.radio-buttons .radio-button {
  display: inline-block;
  margin-right: 1em;
  padding: .5em; }

.radio-buttons .circle {
  display: block;
  float: left;
  width: 1.05em;
  height: 1.05em;
  border-radius: 1em;
  margin: .4em .1em 0 0;
  border: 2px solid #262262; }

.radio-buttons .fa-check-circle {
  display: block;
  float: left;
  font-size: 1.4em;
  margin: .25em 0 0;
  color: green; }

.select select {
  padding: .5rem .25rem; }

.hp {
  display: none; }

form button {
  font-size: 1.25em;
  background: orange;
  padding: .5em 1em;
  border: none;
  border-radius: .25em;
  display: block;
  margin: .5em auto; }
  form button.disabled {
    cursor: not-allowed;
    background: #ccc; }

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.sending {
  font-size: 1.5em;
  display: block;
  text-align: center;
  max-width: 42em;
  padding: 1em 0;
  position: relative; }
  .sending .spinner {
    -webkit-animation: 2s linear infinite spinner;
            animation: 2s linear infinite spinner;
    margin-right: .5em; }

.success {
  font-size: 1.5em;
  display: block;
  text-align: center;
  max-width: 42em;
  padding: 1em 0;
  position: relative; }
  .success .svg-inline--fa {
    margin-right: .5em;
    color: green; }

.check-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%; }
  @media (min-width: 768px) {
    .check-in-form .field {
      display: grid;
      grid-template-columns: 12rem 1fr; } }
  .check-in-form .field label {
    grid-column: 1; }
    @media (min-width: 768px) {
      .check-in-form .field label {
        width: auto; } }
  .check-in-form .field input {
    grid-column: 2; }
    @media (min-width: 768px) {
      .check-in-form .field input[type="text"], .check-in-form .field input[type="phone"] {
        width: calc(100% - 1rem); } }
  .check-in-form .field select {
    width: 100%; }
  .check-in-form .field.radio-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    @media (min-width: 768px) {
      .check-in-form .field.radio-buttons {
        display: grid;
        grid-template-columns: 12rem 1fr; } }
    .check-in-form .field.radio-buttons .radio-button {
      display: block;
      grid-column: 2;
      padding: 0;
      margin-right: 0; }
  .check-in-form .field.child-name {
    display: none; }
  .check-in-form.kids .field.child-name {
    display: grid; }

.pay {
  display: flex;
  justify-content: center;
  align-items: center; }
  .pay.bank {
    display: block; }
    .pay.bank ul {
      list-style-type: none; }
  .pay .svg-inline--fa {
    margin-right: .5rem;
    font-size: 3rem;
    color: #333; }


.product {
  position: relative;
  overflow: auto;
  clear: both;
  padding-bottom: 3em;
  border-bottom: 1px solid #ccc;
  align-items: flex-start; }
  @media (min-width: 768px) {
    .product {
      display: grid;
      grid-template-columns: 15rem 1fr 15rem;
      grid-column-gap: 1rem;
      -webkit-column-gap: 1rem;
              column-gap: 1rem;
      grid-row-gap: .5rem;
      row-gap: .5rem; } }
  .product > div {
    margin-bottom: 0; }
  .product:last-child {
    border-bottom: none; }
  .product h2 {
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .product h2 {
        grid-column: 1 / 3;
        grid-row: 1; } }
  .product input[type="number"] {
    width: 2rem;
    margin: 0 .5rem; }
  .product img {
    width: 100%; }
    @media (min-width: 768px) {
      .product img {
        grid-column: 3;
        grid-row: 1 / 5; } }
  .product .description {
    max-width: 42em;
    clear: left; }
    @media (min-width: 768px) {
      .product .description {
        grid-column: 1 / 3;
        grid-row: 3; } }
  @media (min-width: 768px) {
    .product .adjust-weeks {
      grid-column: 1 / 3;
      grid-row: 4; } }
  .product .price {
    margin-right: 1em; }
    @media (min-width: 768px) {
      .product .price {
        grid-column: 1 / 3;
        grid-row: 2; } }
    .product .price .subscription {
      font-size: 0.8em;
      color: #333;
      margin-left: .5em; }
  .product .adjust-weeks {
    background: #faec446b;
    padding: .5rem; }
  .product button {
    position: relative;
    display: block;
    text-align: center; }
    @media (min-width: 768px) {
      .product button {
        display: inline-block;
        margin-right: 1em;
        grid-column: 1;
        grid-row: 4; } }
    .product button:hover, .product button:focus {
      border-color: orange;
      background: #fff;
      outline: none;
      color: orange; }
    .product button.added {
      color: green;
      border-color: green;
      background: transparent; }
    .product button .svg-inline--fa {
      margin-right: .5em; }
  .product .go {
    text-decoration: none;
    display: flex;
    flex-direction: flex-start;
    align-items: center;
    text-align: center;
    color: orange;
    background: #fff;
    border: none; }
    @media (min-width: 768px) {
      .product .go {
        text-align: left;
        display: inline; } }
    .product .go .svg-inline--fa {
      margin-right: .5em; }
  @media (min-width: 768px) {
    .product.kids button {
      grid-row: 5; } }

.sku:before {
  content: "sku:";
  text-transform: uppercase;
  padding-right: .5em;
  color: #333;
  font-size: 0.8em; }

.Shop p.description {
  margin-bottom: 2rem; }

.Shop .products {
  border-top: 1px solid #ccc;
  padding-top: 1rem; }

.Shop ul.tags {
  margin: 0 auto 1em;
  display: table;
  font-size: 0.8em;
  list-style-type: none;
  margin-bottom: 1em;
  overflow: auto;
  max-width: 100% !important; }
  .Shop ul.tags li {
    float: left; }
    .Shop ul.tags li:first-child {
      margin-left: 0; }
    .Shop ul.tags li button {
      padding: .25em 1em;
      text-transform: uppercase;
      border: 1px solid #262262;
      margin-right: .5em; }
      .Shop ul.tags li button:focus, .Shop ul.tags li button:hover {
        outline: none;
        border-color: orange;
        background: orange;
        color: #fff; }
      .Shop ul.tags li button.selected {
        background: #262262;
        color: #fff; }
        .Shop ul.tags li button.selected:focus, .Shop ul.tags li button.selected:hover {
          background: orange;
          border-color: orange;
          color: #fff; }
      .Shop ul.tags li button.clear-tags {
        border: none; }

.breadcrumbs {
  font-family: 'Overpass Mono', monospace;
  text-transform: uppercase;
  font-size: 0.8em;
  font-size: 1em;
  color: #333; }
  .breadcrumbs .svg-inline--fa {
    padding: 0 .5em;
    color: #ccc; }

.basket {
  overflow: auto; }
  .cart .basket {
    clear: both; }
  .checkout .basket {
    margin-top: 2em;
    clear: both;
    float: none; }
    @media (min-width: 768px) {
      .checkout .basket {
        margin-top: 0;
        clear: none;
        float: right;
        width: 48%; } }
  .basket .row {
    border: #ccc 1px solid;
    border-bottom: none;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 3em 1fr 5em;
    align-items: center; }
    .basket .row:last-child {
      border-bottom: #ccc 1px solid; }
    @media (min-width: 768px) {
      .basket .row {
        display: grid;
        grid-template-columns: 4em 4em 1fr 6.55em; } }
    .basket .row div {
      margin-bottom: 0; }
    .basket .row .quantity {
      display: grid;
      grid-template-columns: 1fr 1em;
      grid-template-rows: 50% 50%;
      padding: 0 .5em 0;
      height: 3em; }
      @media (min-width: 768px) {
        .basket .row .quantity {
          height: auto; } }
      .checkout .basket .row .quantity {
        display: block; }
      .basket .row .quantity .value {
        grid-row: 1 / 3;
        grid-column: 1;
        display: flex;
        justify-content: center;
        align-items: center; }
      .basket .row .quantity .alter {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8em;
        grid-column: 2;
        grid-row: 1; }
        .basket .row .quantity .alter.minus {
          grid-row: 2; }
        .basket .row .quantity .alter.pro-rated {
          display: none; }
        .checkout .basket .row .quantity .alter {
          display: none; }
    .basket .row .image {
      display: none; }
      @media (min-width: 768px) {
        .basket .row .image {
          display: flex;
          justify-content: center;
          align-items: center; } }
    .basket .row .details {
      padding: .5em 0 .5em 1em;
      display: block;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc; }
      .basket .row .details span {
        display: block;
        clear: left;
        float: none;
        color: #333; }
        .basket .row .details span.name {
          font-weight: 600; }
        .basket .row .details span.description {
          font-size: 0.8em; }
      @media (min-width: 768px) {
        .basket .row .details {
          border: none; } }
    .basket .row .price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: .5em;
      text-align: right; }
  .basket .totes {
    margin-top: 1em;
    clear: both; }
    @media (min-width: 768px) {
      .basket .totes {
        width: 13.2em;
        float: right; } }
    .basket .totes .total-row {
      border: #ccc 1px solid;
      border-bottom: none;
      margin-bottom: 0;
      clear: both;
      overflow: auto; }
      .basket .totes .total-row .label {
        width: calc(50% - 1em);
        text-align: right;
        display: inline-block;
        background: rgba(204, 204, 204, 0.5);
        padding: .5em;
        margin-right: 0; }
      .basket .totes .total-row .value {
        width: calc(50% - .5em);
        padding-right: .5em;
        text-align: right;
        display: inline-block; }
      .basket .totes .total-row:last-child {
        border-bottom: #ccc 1px solid; }
  .basket .no-gst {
    margin-top: 1em;
    color: #333; }

.checkout-form {
  overflow: auto;
  margin-top: 3em;
  position: relative; }
  .checkout-form div {
    margin-bottom: 0; }
  .checkout-form .empty {
    display: none; }
  @media (min-width: 768px) {
    .checkout-form .info {
      width: 43%;
      float: left;
      border-right: 1px solid #ccc;
      padding-right: 5%; } }
  .checkout-form .error {
    text-align: right;
    float: right;
    clear: right;
    margin-top: 1em;
    color: #fff;
    background: red;
    display: inline-block;
    padding: 0.25em .5em; }
  .checkout-form .group-label {
    margin: 2em 0 1em;
    font-size: 1em;
    font-weight: bold; }
    .checkout-form .group-label:first-child {
      margin-top: 0; }
    .checkout-form .group-label .svg-inline--fa {
      margin-right: 1em; }
  .checkout-form .label {
    display: block;
    width: 7em;
    float: left;
    text-align: left;
    margin: -.1em 1em 0 0;
    font-size: 0.8em; }
  .checkout-form label {
    width: 7em; }
  .checkout-form .input-row {
    margin: 0;
    padding-bottom: .5em; }
    @media (min-width: 768px) {
      .checkout-form .input-row.number .label {
        width: 7em;
        margin-right: .5em; } }
    .checkout-form .input-row.expiry {
      float: left;
      width: 9em; }
      @media (min-width: 768px) {
        .checkout-form .input-row.expiry {
          width: 15em; } }
      .checkout-form .input-row.expiry .label {
        width: 4em;
        margin-right: .25em; }
        @media (min-width: 768px) {
          .checkout-form .input-row.expiry .label {
            width: 5em;
            margin-right: .5em; } }
    .checkout-form .input-row.cvc {
      float: left;
      width: 7em; }
      @media (min-width: 768px) {
        .checkout-form .input-row.cvc {
          width: 9em; } }
      .checkout-form .input-row.cvc .label {
        width: 2em;
        margin-right: .25em; }
        @media (min-width: 768px) {
          .checkout-form .input-row.cvc .label {
            width: 3em; } }
  .checkout-form input {
    width: 10em; }
    @media (min-width: 768px) {
      .checkout-form input {
        width: calc(100% - 7em); } }
    @media (min-width: 768px) {
      .checkout-form input[name="suburb"] {
        width: 8em; } }
    @media (min-width: 768px) {
      .checkout-form input[name="zip"] {
        width: 4em; } }
  .checkout-form .card-input {
    width: 100%; }
    @media (min-width: 768px) {
      .checkout-form .card-input {
        padding: .5em 0; } }
  .checkout-form .button {
    clear: none;
    display: block;
    margin-top: 2em;
    text-align: center; }
    @media (min-width: 768px) {
      .checkout-form .button {
        clear: right;
        float: right;
        display: inline-block;
        width: 9.25em; } }
    .checkout-form .button.inactive {
      cursor: not-allowed;
      border: 1px solid #ccc; }
    .checkout-form .button.sending {
      cursor: not-allowed;
      color: #fff;
      background: green;
      border: 1px solid green;
      font-size: 1em;
      padding: .5em 0;
      width: 13em; }

.StripeElement {
  padding-top: .5em;
  display: inline-block;
  border-bottom: 1px solid #1f1f1f;
  width: 50%;
  padding: .1em .25em; }
  .invalid .StripeElement {
    border-bottom-color: red; }

.stripe-error {
  text-align: right;
  margin-top: 1em;
  color: #fff;
  background: red;
  display: inline-block;
  padding: 0.25em .5em; }
  @media (min-width: 768px) {
    .stripe-error {
      float: right;
      max-width: 40%; } }
  .stripe-error .svg-inline--fa {
    margin-right: .5em; }

.successful-payment span {
  margin-right: .5em; }
  .successful-payment span.number, .successful-payment span.plans {
    border: 1px solid #ccc;
    padding: .25em;
    border-radius: .2em;
    background: rgba(255, 165, 0, 0.25);
    font-size: 0.8em; }

.successful-payment .button {
  margin-top: 1em; }

.cart .checkout {
  display: block;
  text-align: center; }
  @media (min-width: 768px) {
    .cart .checkout {
      display: inline-block;
      clear: both;
      float: right; } }

.checkout {
  position: relative; }
  .checkout .notice {
    font-size: 0.8em;
    color: #333;
    text-align: center; }
    @media (min-width: 768px) {
      .checkout .notice {
        position: absolute;
        bottom: 0;
        width: calc(100% - 4em); } }
    .checkout .notice .fa-lock {
      color: green; }


.home .interest-form > div {
  margin-bottom: 1em; }

.page.landing .interest-form {
  clear: both;
  margin: 2em 0 0; }

.interest-form h2 {
  text-align: center; }

.page.landing .interest-form .button-container.claim {
  margin-bottom: 0; }

.interest-form .button-container.claim .button {
  width: calc(100% - 4em);
  text-align: center; }
  @media (min-width: 768px) {
    .interest-form .button-container.claim .button {
      width: auto; } }
  .page.landing .interest-form .button-container.claim .button {
    margin-bottom: 0; }

.interest-form form {
  background: #fff;
  max-width: 100%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .interest-form form {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    .interest-form form {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    .interest-form form {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    .interest-form form {
      text-align: left;
      margin: 0 auto;
      width: 50%; }
      .sidebar .interest-form form {
        width: 100%; } }
  .interest-form form button {
    font-size: 1.25em; }

.interest-form label {
  width: 4em;
  font-size: 1em;
  text-align: left; }

.interest-form input {
  width: 90%; }
  @media (min-width: 768px) {
    .interest-form input {
      width: calc(90% - 4em); } }

.interest-form .textarea {
  display: none; }

@media (min-width: 768px) {
  .interest-form .child-name label {
    width: 6em; } }

@media (min-width: 768px) {
  .interest-form .child-name input {
    width: 13em; } }

@media (min-width: 768px) {
  .interest-form textarea {
    width: 90%; } }

@media (min-width: 768px) {
  .sidebar {
    max-width: 16em;
    float: right; } }

@media (min-width: 768px) {
  .page .content {
    float: left;
    clear: none; } }

.LearnAikidoDotPoints {
  padding: 4rem 1rem; }
  @media (min-width: 768px) {
    .LearnAikidoDotPoints {
      padding: 4rem 0; } }
  .LearnAikidoDotPoints h2 {
    text-align: center; }
  .LearnAikidoDotPoints ul {
    list-style-type: none;
    max-width: 100%; }
    @media (min-width: 768px) {
      .LearnAikidoDotPoints ul {
        display: grid;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-evenly;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 1rem;
        row-gap: 1rem;
        grid-column-gap: 1rem;
        -webkit-column-gap: 1rem;
                column-gap: 1rem;
        margin: 1rem; } }
    .LearnAikidoDotPoints ul li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 2rem; }
      .LearnAikidoDotPoints ul li .img {
        background-color: #262262;
        margin: 0 0 .5rem 0;
        width: 20rem;
        height: 20rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: luminosity;
        -webkit-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out; }
        .LearnAikidoDotPoints ul li .img:hover {
          background-color: transparent;
          background-blend-mode: none; }
        @media (min-width: 768px) {
          .LearnAikidoDotPoints ul li .img {
            width: 100%;
            height: 9rem; } }
      .LearnAikidoDotPoints ul li .text {
        font-weight: bold;
        color: #262262;
        font-size: 1.25rem;
        line-height: 3rem;
        text-align: center; }
        @media (min-width: 768px) {
          .LearnAikidoDotPoints ul li .text {
            font-size: 1rem;
            line-height: 2rem; } }
  .LearnAikidoDotPoints .interest-form .button-container {
    text-align: center; }
  .LearnAikidoDotPoints.adults .point-4 .img {
    background-position-x: -36rem;
    background-size: inherit; }
    @media (min-width: 768px) {
      .LearnAikidoDotPoints.adults .point-4 .img {
        background-position-x: center;
        background-size: cover; } }

@media (min-width: 768px) {
  .page.landing.geo h1 {
    font-size: 5rem; } }

.page.landing img {
  margin-bottom: 2em;
  width: 100%; }

.address .locations {
  margin: 1rem 0; }
  .location .address .locations {
    margin-bottom: 2em; }
    .location .address .locations h2 {
      display: none; }
    .location .address .locations h3 {
      margin-top: 0; }
  .address .locations div[itemprop="address"] {
    margin-top: 1em; }
  .address .locations [itemprop="streetAddress"] {
    display: block; }
  .address .locations [itemprop="postalCode"] {
    margin-right: .5em; }

.address span {
  display: inline-block;
  clear: left; }
  .address span[itemprop="telephone"] a {
    margin-left: .5em; }


.faq {
  position: relative;
  max-width: 42em;
  border-bottom: solid 1px #ccc;
  clear: both;
  overflow: auto; }
  @media (min-width: 768px) {
    .faq {
      width: 42em; } }
  .faq .heading {
    cursor: pointer; }
    .faq .heading .svg-inline--fa {
      margin-right: .5em;
      font-size: 1.25em;
      position: absolute; }
    .faq .heading h2 {
      margin-left: 1.5em; }
  @media (min-width: 768px) {
    .faq .content {
      margin-left: 2em; } }
  .faq .content.show {
    display: block; }
  .faq .content.hide {
    display: none; }


.rank {
  position: relative; }
  .rank .heading {
    border-bottom: 1px solid #ccc;
    margin-bottom: 0;
    padding: 0.5em;
    overflow: auto;
    display: grid;
    grid-template-columns: 2em 1fr 8em; }
    @media print {
      .rank .heading {
        background: none;
        grid-template-columns: 1fr 8em;
        padding: 0; } }
    @media print {
      .rank .heading.closed {
        margin-bottom: 1em;
        border-bottom: 2px solid #1f1f1f; } }
    .rank .heading.open {
      margin-bottom: 1em; }
    .rank .heading .svg-inline--fa {
      margin-right: .5em;
      font-size: 1.25em;
      grid-column: 1;
      text-align: center;
      margin: 0; }
      @media print {
        .rank .heading .svg-inline--fa {
          display: none; } }
    .rank .heading h2 {
      margin: 0;
      grid-column: 2; }
      @media print {
        .rank .heading h2 {
          grid-column: 1; } }
    .rank .heading .colour {
      font-family: 'Overpass Mono', monospace;
      text-transform: uppercase;
      font-size: 0.8em;
      grid-column: 3;
      color: #fff;
      margin: 0;
      padding: 0.25em .5em;
      border-radius: .25em;
      background: #333;
      width: 90%;
      text-align: center; }
      .rank .heading .colour.brown {
        background: brown; }
      .rank .heading .colour.purple {
        background: purple; }
      .rank .heading .colour.red {
        background: red; }
      .rank .heading .colour.green {
        background: green; }
      .rank .heading .colour.blue {
        background: #262262; }
      .rank .heading .colour.orange {
        background: orange; }
      .rank .heading .colour.yellow {
        background: yellow;
        color: #1f1f1f; }
      @media print {
        .rank .heading .colour {
          grid-column: 2;
          color: #1f1f1f;
          background: none !important;
          text-align: right;
          font-size: 1em; }
          .rank .heading .colour:after {
            content: ' belt'; } }
  .rank .hide {
    display: none; }
    @media print {
      .rank .hide {
        display: block; } }
  @media print {
    .rank .techniques {
      page-break-after: always; } }
  .rank .techniques .group {
    border-bottom: 1px solid #ccc;
    padding: .5em 0;
    margin-bottom: 0; }
    .rank .techniques .group:last-child {
      border-bottom: none; }
    @media (min-width: 768px) {
      .rank .techniques .group {
        display: grid;
        grid-template-columns: 25% 1fr; }
        .rank .techniques .group.grade {
          grid-template-columns: 25% 1fr 15em; } }
    @media print {
      .rank .techniques .group {
        display: grid;
        grid-template-columns: 30% 1fr; } }
    .rank .techniques .group div {
      margin-bottom: 0; }
    .rank .techniques .group .label {
      grid-column: 1;
      font-size: 1em;
      margin-bottom: 0; }
    .rank .techniques .group .content {
      grid-column: 2; }
  .rank .summary {
    position: fixed;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    width: calc(100% - 2em); }
    @media (min-width: 1000px) {
      .rank .summary {
        width: calc(1000px - 1.8em); } }
    .rank .summary > div {
      margin-bottom: 0; }
    .rank .summary .total {
      font-family: 'Overpass Mono', monospace;
      text-transform: uppercase;
      font-size: 0.8em;
      font-size: 1.5em;
      line-height: 1.25em;
      font-weight: bold;
      width: 100%;
      text-align: center; }
      .rank .summary .total.pass {
        color: green; }
      .rank .summary .total.fail {
        color: red; }
    .rank .summary .unscored,
    .rank .summary .max {
      text-align: center; }
  .rank .score {
    font-family: 'Overpass Mono', monospace;
    text-transform: uppercase;
    font-size: 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1em; }
    @media (min-width: 1000px) {
      .rank .score {
        margin-top: 0; } }
    .rank .score .number {
      font-family: 'Overpass Mono', monospace;
      text-transform: uppercase;
      font-size: 0.8em;
      font-size: 1.2em;
      padding: .25em 1em;
      border: 1px solid #ccc;
      border-radius: .25em;
      background: #ccc;
      font-weight: bold; }
      .rank .score .number.selected {
        background: green; }
        .rank .score .number.selected.five {
          color: white; }
        .rank .score .number.selected.four {
          background: #00e600; }
        .rank .score .number.selected.three {
          background: orange; }
        .rank .score .number.selected.two {
          background: #ff6666; }
        .rank .score .number.selected.one {
          background: red; }
  .rank .comment {
    font-family: 'Overpass Mono', monospace;
    text-transform: uppercase;
    font-size: 0.8em;
    text-align: center; }
    .rank .comment.no-grade {
      color: red; }

.dan-rank-row {
  border-bottom: 1px solid #ccc;
  padding: .5em 0;
  margin-bottom: 0; }
  .dan-rank-row:last-child {
    border-bottom: none; }
  @media (min-width: 768px) {
    .dan-rank-row {
      display: grid;
      grid-template-columns: 25% 1fr; }
      .dan-rank-row.grade {
        grid-template-columns: 25% 1fr 15em; } }
  @media print {
    .dan-rank-row {
      display: grid;
      grid-template-columns: 30% 1fr; } }
  .dan-rank-row div {
    margin-bottom: 0; }
  .dan-rank-row .label {
    grid-column: 1;
    font-size: 1em;
    margin-bottom: 0; }
  .dan-rank-row .content {
    grid-column: 2; }
  .dan-rank-row .attacks,
  .dan-rank-row .variations {
    margin-bottom: .25em;
    display: grid;
    grid-template-columns: 5.5em 1fr; }
    .dan-rank-row .attacks .label-a,
    .dan-rank-row .variations .label-a {
      font-size: 1em;
      grid-column: 1; }
      .dan-rank-row .attacks .label-a:after,
      .dan-rank-row .variations .label-a:after {
        content: ":"; }
    .dan-rank-row .attacks .content,
    .dan-rank-row .variations .content {
      grid-column: 2; }
      @media (min-width: 1000px) {
        .dan-rank-row .attacks .content,
        .dan-rank-row .variations .content {
          padding-right: .5em; } }

form {
  font-size: 1em; }
  @media (min-width: 768px) {
    form {
      max-width: 42em; } }
  form.hidden {
    display: none; }
  form > div {
    margin-bottom: 1em; }
    form > div:last-child {
      margin-bottom: 0; }
  form .honeypot {
    display: none; }
  form .hidden {
    display: none; }

.invalid {
  border-bottom-color: red; }

.valid {
  border-bottom-color: green; }

.location .contact {
  margin-bottom: 2em; }
  .location .contact h2 {
    display: none; }
  .location .contact h3 {
    margin-top: 0; }

.contact div[itemprop="address"] {
  margin-top: 1em; }

.contact span {
  display: inline-block;
  clear: left; }
  .contact span[itemprop="telephone"] a {
    margin-left: .5em; }
  .contact span[itemprop="streetAddress"] {
    display: block; }
  .contact span[itemprop="postalCode"] {
    margin-right: .5em; }

.search-form {
  overflow: auto; }
  @media (min-width: 768px) {
    header .search-form {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      grid-column: 3; } }
  @media print {
    header .search-form {
      display: none; } }
  @media (min-width: 768px) {
    header .search-form form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      width: 20em;
      margin-right: 3em; } }
  @media (min-width: 1000px) {
    header .search-form form {
      margin-right: 0; } }
  .search-form label {
    display: none; }
  .search-form input {
    padding: .5em;
    width: calc(100% - 8em);
    border: 1px solid #ccc;
    float: left;
    font-size: .925em; }
    @media (min-width: 768px) {
      .search-form input {
        display: block;
        width: calc(100% - 6em);
        border: 1px solid #ccc;
        margin: 1.4em 0 0 0; } }
    .search-form input.show {
      display: block; }
    header .search-form input {
      display: none;
      padding: .5em;
      width: calc(100% - 2em);
      margin: 0; }
      @media (min-width: 768px) {
        header .search-form input {
          display: block;
          width: calc(100% - 7em);
          float: left;
          border: 1px solid #ccc;
          margin: 0; } }
    section .search-form input {
      margin-top: 0;
      width: calc(100% - 4em); }
  .search-form button {
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0;
    padding: .67em 1em;
    position: relative;
    background: transparent;
    float: left;
    margin: 0;
    right: 0;
    font-size: 11px; }
    @media (min-width: 768px) {
      .search-form button {
        border: 1px solid #ccc;
        border-left: none;
        border-radius: 0;
        padding: .67em 1em;
        margin-top: 1.4em; } }
    header .search-form button {
      position: absolute;
      background: transparent;
      border: none;
      top: 1.5em;
      padding: .5em .75em 1em 1em; }
      @media (min-width: 768px) {
        header .search-form button {
          border: 1px solid #ccc;
          border-left: none;
          border-radius: 0;
          padding: .67em 1em;
          position: relative;
          top: inherit;
          margin: 0; } }
    section .search-form button {
      position: relative;
      float: left;
      margin: 0;
      padding: .7em 1em; }
      @media (min-width: 768px) {
        section .search-form button {
          padding: .67em 1em; } }
    .search-form button .search-icon {
      font-size: 1.75em; }
    .search-form button .search-text {
      display: none; }
  .search-form.mobile-show {
    position: relative;
    margin: 0 auto;
    width: 55%;
    margin-bottom: 1em; }
    @media (min-width: 768px) {
      .search-form.mobile-show {
        width: 70%;
        margin: 1.2em 0 0 auto; } }
    .search-form.mobile-show input {
      margin-top: 0;
      width: calc(100% - 4em); }
    .search-form.mobile-show button {
      border: 1px solid #ccc;
      border-left: none;
      border-radius: 0;
      padding: .67em 1em;
      top: 0;
      margin: 0;
      padding: .65em 1em; }
    section .search-form.mobile-show {
      margin: 1em 0; }
      @media (min-width: 768px) {
        section .search-form.mobile-show {
          display: block;
          width: 50%;
          margin: 0; } }
  section .search-form {
    width: 100%; }
    @media (min-width: 768px) {
      section .search-form {
        width: 32em; } }

ul.pagination {
  list-style-type: none;
  display: block;
  clear: both;
  text-align: center;
  overflow: auto;
  max-width: 42em;
  margin: 2em 0;
  padding: 0; }
  ul.pagination li {
    display: inline-block;
    border-radius: .25em;
    background: #262262;
    padding: .5em 1em;
    margin-right: .5em;
    color: #fff; }
    ul.pagination li.dots, ul.pagination li.next, ul.pagination li.previous {
      background: transparent; }
    ul.pagination li:last-child {
      margin-right: 0; }

.search-results .listing {
  clear: both;
  overflow: auto;
  margin-bottom: 0;
  max-width: 42em; }
  .search-results .listing .result {
    display: block;
    clear: both;
    overflow: auto;
    margin-bottom: 2em;
    cursor: pointer; }
    .search-results .listing .result h2 {
      margin-bottom: 0.5em; }
      @media (min-width: 768px) {
        .search-results .listing .result h2 {
          margin-bottom: 0.25em; } }
    .search-results .listing .result .type {
      text-transform: uppercase;
      color: #262262; }
    .search-results .listing .result .image {
      position: relative;
      color: white;
      overflow: hidden;
      width: 2em;
      height: 2em;
      float: left;
      margin-right: .5em;
      margin-bottom: 0;
      background: center center no-repeat;
      background-size: cover;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      @media (min-width: 768px) {
        .search-results .listing .result .image {
          width: 8em;
          height: 8em;
          margin-right: 1.5em; } }
      .search-results .listing .result .image .text-container {
        background: rgba(38, 34, 98, 0.75);
        margin: 0;
        padding: 0;
        height: .8em;
        top: 2em; }
        @media (min-width: 768px) {
          .search-results .listing .result .image .text-container {
            height: 2.1em;
            top: 3em; } }
        .search-results .listing .result .image .text-container .text {
          font-family: 'Zilla Slab Highlight', sans-serif;
          font-size: .8em;
          padding: 0;
          margin: -.3em 0 0 0; }
          @media (min-width: 768px) {
            .search-results .listing .result .image .text-container .text {
              font-size: 3em;
              padding: .2em 0 0;
              margin: -.1em 0; } }

.search-results .no-results {
  clear: both;
  overflow: auto;
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 100%;
  height: 15em; }
  @media (min-width: 768px) {
    .search-results .no-results {
      width: 15em;
      height: 15em; } }
  .search-results .no-results .no-results-text {
    background-color: rgba(38, 34, 98, 0.7);
    position: absolute;
    margin: 0 0 0 32px;
    bottom: 0;
    padding: 0;
    overflow: hidden; }
    .search-results .no-results .no-results-text .text {
      font-family: 'Zilla Slab Highlight', sans-serif;
      color: white;
      font-size: 2.25em;
      margin: 0;
      padding: 0; }
  .search-results .no-results img {
    float: left;
    margin-right: 1em; }

footer {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  font-size: 0.8em;
  line-height: 1.5em;
  clear: both;
  color: #fff;
  background: #333;
  padding: 2em 1.2em 4em; }
  @media (min-width: 768px) {
    footer {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    footer {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    footer {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    footer {
      display: grid;
      grid-template-columns: 1fr 1fr; } }
  @media print {
    footer {
      display: none; } }
  footer > div {
    margin-top: 1em; }
    @media (min-width: 768px) {
      footer > div {
        margin-top: 0; } }
  footer h3 {
    margin: 0 0 .25em; }
  footer a {
    color: #fff;
    text-decoration: none; }
    footer a:visited {
      color: #fff; }
    footer a:hover {
      color: orange; }
  footer .details {
    margin-top: .5em; }
    footer .details .fa-info-circle {
      margin-right: .5em; }

* {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-family: inherit; }
  *:focus {
    outline: 1px solid orange; }

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  background: #fff url("/images/seigaiha.png") repeat fixed; }
  @media print {
    body {
      background: none;
      font-size: 10pt; } }

.container {
  background: #fff;
  max-width: 100%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .container {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    .container {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1000px; } }

section {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  background: #fff;
  padding: 2em 1em 4em;
  line-height: 1.5em; }
  @media (min-width: 768px) {
    section {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    section {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    section {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    section {
      max-width: calc(768px - 4em);
      padding: 2em 2em 4em; } }
  @media (min-width: 1000px) {
    section {
      max-width: 918px; } }
  @media (min-width: 1200px) {
    section {
      max-width: 970px; } }
  @media print {
    section {
      padding-top: 1em; } }
  section div,
  section article {
    margin-bottom: 1em; }
  @media (min-width: 768px) {
    section .content {
      float: left; } }
  section a {
    background: orange;
    color: #fff;
    font-weight: 700;
    padding: 0 .25em;
    text-decoration: none; }
    section a:visited {
      background: orange;
      color: #fff; }

article {
  margin: 0; }

blockquote {
  border-left: 4px solid #262262;
  margin: 1em 0;
  padding: 1em 2em 1em 2em;
  background: rgba(31, 31, 31, 0.1);
  max-width: 42em;
  font-style: italic; }

p {
  margin: 0 0 1em;
  max-width: 42em; }
  p:last-child {
    margin-bottom: 0; }

article ol,
section ol, article
ul,
section
ul {
  max-width: 42em;
  padding-left: 1.25em;
  margin-top: 1em;
  margin-bottom: 2em; }
  article ol:last-child,
  section ol:last-child, article
  ul:last-child,
  section
  ul:last-child {
    margin-bottom: 0; }
  article ol ul,
  section ol ul, article
  ul ul,
  section
  ul ul {
    margin-top: .5em; }
    li article ol ul, li
    section ol ul, li article
    ul ul, li
    section
    ul ul {
      margin-bottom: 0; }

article ol li,
section ol li, article
ul li,
section
ul li {
  margin-bottom: .5em; }

dl {
  margin: 0; }

h1 {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  font-size: 1.25em;
  font-weight: bold;
  color: #262262;
  padding: 1em 16px;
  background: #fff; }
  @media (min-width: 768px) {
    h1 {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    h1 {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    h1 {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    h1 {
      font-size: 1.5em; } }
  @media (min-width: 1000px) {
    h1 {
      font-size: 2.25em; } }

h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 2em 0 1em;
  line-height: auto; }
  h2:first-of-type {
    margin-top: 0; }

h3 {
  font-size: 1em;
  font-weight: bold;
  margin: 1em 0;
  line-height: 1.6em; }

a {
  color: orange; }
  a.button {
    background: orange;
    padding: 1em 2em;
    color: #fff;
    border: 1px solid orange;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none; }
    a.button:visited {
      color: white; }
    a.button:hover, a.button:focus {
      border: 1px solid orange;
      color: orange;
      background: transparent;
      outline: none;
      text-shadow: 1px 1px 1px #ccc; }
    a.button.inactive {
      background: #ccc;
      color: #1f1f1f; }
    a.button.secondary {
      background: #fff;
      color: orange; }
      a.button.secondary:hover {
        background: orange;
        color: #fff; }
    a.button .svg-inline--fa {
      margin-right: .5rem; }

figcaption {
  font-family: 'Overpass Mono', monospace;
  text-transform: uppercase;
  font-size: 0.8em;
  text-align: center; }

.label,
label {
  font-family: 'Overpass Mono', monospace;
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 0.5em 0;
  margin: 0; }

.error {
  color: red;
  font-weight: bold; }

.button {
  background: orange;
  padding: 1em 2em;
  color: #fff;
  border: 1px solid orange;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block; }
  .button:visited {
    color: white; }
  .button:hover, .button:focus {
    border: 1px solid orange;
    color: orange;
    background: transparent;
    outline: none;
    text-shadow: 1px 1px 1px #ccc; }
  .button.inactive {
    background: #ccc;
    color: #1f1f1f; }
  .button.secondary {
    background: #fff;
    color: orange; }
    .button.secondary:hover {
      background: orange;
      color: #fff; }
  .button .svg-inline--fa {
    margin-right: .5rem; }
  .sidebar .button {
    padding: 1em; }

.hide {
  display: none; }

.clear {
  clear: both; }

.small {
  font-size: 0.8em; }

.right {
  float: right; }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn); } }

.fa-spinner {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear; }

.submitting {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(31, 31, 31, 0.75); }
  .submitting .fa-spinner {
    color: #fff; }

.toggle {
  display: none;
  overflow: auto; }
  .toggle.show {
    display: block; }

#root {
  max-width: 100%; }

.container {
  padding: 0 1em; }
  @media (min-width: 768px) {
    .container {
      max-width: 1200px;
      margin: 0 auto; } }

