@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400|Zilla+Slab+Highlight:700|Overpass+Mono&display=swap';
* {
  margin: 0;
  padding: 0;
  font-size: 1em;
  font-family: inherit; }
  *:focus {
    outline: 1px solid orange; }

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  background: #fff url("/images/seigaiha.png") repeat fixed; }
  @media print {
    body {
      background: none;
      font-size: 10pt; } }

.container {
  background: #fff;
  max-width: 100%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .container {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    .container {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1000px; } }

section {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  background: #fff;
  padding: 2em 1em 4em;
  line-height: 1.5em; }
  @media (min-width: 768px) {
    section {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    section {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    section {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    section {
      max-width: calc(768px - 4em);
      padding: 2em 2em 4em; } }
  @media (min-width: 1000px) {
    section {
      max-width: 918px; } }
  @media (min-width: 1200px) {
    section {
      max-width: 970px; } }
  @media print {
    section {
      padding-top: 1em; } }
  section div,
  section article {
    margin-bottom: 1em; }
  @media (min-width: 768px) {
    section .content {
      float: left; } }
  section a {
    background: orange;
    color: #fff;
    font-weight: 700;
    padding: 0 .25em;
    text-decoration: none; }
    section a:visited {
      background: orange;
      color: #fff; }

article {
  margin: 0; }

blockquote {
  border-left: 4px solid #262262;
  margin: 1em 0;
  padding: 1em 2em 1em 2em;
  background: rgba(31, 31, 31, 0.1);
  max-width: 42em;
  font-style: italic; }

p {
  margin: 0 0 1em;
  max-width: 42em; }
  p:last-child {
    margin-bottom: 0; }

article ol,
section ol, article
ul,
section
ul {
  max-width: 42em;
  padding-left: 1.25em;
  margin-top: 1em;
  margin-bottom: 2em; }
  article ol:last-child,
  section ol:last-child, article
  ul:last-child,
  section
  ul:last-child {
    margin-bottom: 0; }
  article ol ul,
  section ol ul, article
  ul ul,
  section
  ul ul {
    margin-top: .5em; }
    li article ol ul, li
    section ol ul, li article
    ul ul, li
    section
    ul ul {
      margin-bottom: 0; }

article ol li,
section ol li, article
ul li,
section
ul li {
  margin-bottom: .5em; }

dl {
  margin: 0; }

h1 {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  font-size: 1.25em;
  font-weight: bold;
  color: #262262;
  padding: 1em 16px;
  background: #fff; }
  @media (min-width: 768px) {
    h1 {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    h1 {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    h1 {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    h1 {
      font-size: 1.5em; } }
  @media (min-width: 1000px) {
    h1 {
      font-size: 2.25em; } }

h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 2em 0 1em;
  line-height: auto; }
  h2:first-of-type {
    margin-top: 0; }

h3 {
  font-size: 1em;
  font-weight: bold;
  margin: 1em 0;
  line-height: 1.6em; }

a {
  color: orange; }
  a.button {
    background: orange;
    padding: 1em 2em;
    color: #fff;
    border: 1px solid orange;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none; }
    a.button:visited {
      color: white; }
    a.button:hover, a.button:focus {
      border: 1px solid orange;
      color: orange;
      background: transparent;
      outline: none;
      text-shadow: 1px 1px 1px #ccc; }
    a.button.inactive {
      background: #ccc;
      color: #1f1f1f; }
    a.button.secondary {
      background: #fff;
      color: orange; }
      a.button.secondary:hover {
        background: orange;
        color: #fff; }
    a.button .svg-inline--fa {
      margin-right: .5rem; }

figcaption {
  font-family: 'Overpass Mono', monospace;
  text-transform: uppercase;
  font-size: 0.8em;
  text-align: center; }

.label,
label {
  font-family: 'Overpass Mono', monospace;
  text-transform: uppercase;
  font-size: 0.8em;
  padding: 0.5em 0;
  margin: 0; }

.error {
  color: red;
  font-weight: bold; }

.button {
  background: orange;
  padding: 1em 2em;
  color: #fff;
  border: 1px solid orange;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block; }
  .button:visited {
    color: white; }
  .button:hover, .button:focus {
    border: 1px solid orange;
    color: orange;
    background: transparent;
    outline: none;
    text-shadow: 1px 1px 1px #ccc; }
  .button.inactive {
    background: #ccc;
    color: #1f1f1f; }
  .button.secondary {
    background: #fff;
    color: orange; }
    .button.secondary:hover {
      background: orange;
      color: #fff; }
  .button .svg-inline--fa {
    margin-right: .5rem; }
  .sidebar .button {
    padding: 1em; }

.hide {
  display: none; }

.clear {
  clear: both; }

.small {
  font-size: 0.8em; }

.right {
  float: right; }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  to {
    transform: rotate(1turn); } }

.fa-spinner {
  animation: fa-spin 2s infinite linear; }

.submitting {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(31, 31, 31, 0.75); }
  .submitting .fa-spinner {
    color: #fff; }

.toggle {
  display: none;
  overflow: auto; }
  .toggle.show {
    display: block; }

#root {
  max-width: 100%; }

.container {
  padding: 0 1em; }
  @media (min-width: 768px) {
    .container {
      max-width: 1200px;
      margin: 0 auto; } }
