@media (min-width: 768px) {
  .field {
    display: grid;
    grid-template-columns: 10rem 30rem; } }

.field input {
  font-size: 1em;
  padding: 0.5em;
  border: none;
  border-bottom: 1px solid #262262; }
  .field input.invalid {
    border-color: red; }
  @media (min-width: 768px) {
    .field input {
      grid-row: 1;
      grid-column: 2; } }
