.search-results .listing {
  clear: both;
  overflow: auto;
  margin-bottom: 0;
  max-width: 42em; }
  .search-results .listing .result {
    display: block;
    clear: both;
    overflow: auto;
    margin-bottom: 2em;
    cursor: pointer; }
    .search-results .listing .result h2 {
      margin-bottom: 0.5em; }
      @media (min-width: 768px) {
        .search-results .listing .result h2 {
          margin-bottom: 0.25em; } }
    .search-results .listing .result .type {
      text-transform: uppercase;
      color: #262262; }
    .search-results .listing .result .image {
      position: relative;
      color: white;
      overflow: hidden;
      width: 2em;
      height: 2em;
      float: left;
      margin-right: .5em;
      margin-bottom: 0;
      background: center center no-repeat;
      background-size: cover;
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      @media (min-width: 768px) {
        .search-results .listing .result .image {
          width: 8em;
          height: 8em;
          margin-right: 1.5em; } }
      .search-results .listing .result .image .text-container {
        background: rgba(38, 34, 98, 0.75);
        margin: 0;
        padding: 0;
        height: .8em;
        top: 2em; }
        @media (min-width: 768px) {
          .search-results .listing .result .image .text-container {
            height: 2.1em;
            top: 3em; } }
        .search-results .listing .result .image .text-container .text {
          font-family: 'Zilla Slab Highlight', sans-serif;
          font-size: .8em;
          padding: 0;
          margin: -.3em 0 0 0; }
          @media (min-width: 768px) {
            .search-results .listing .result .image .text-container .text {
              font-size: 3em;
              padding: .2em 0 0;
              margin: -.1em 0; } }

.search-results .no-results {
  clear: both;
  overflow: auto;
  background-size: cover;
  background-position: center center;
  position: relative;
  width: 100%;
  height: 15em; }
  @media (min-width: 768px) {
    .search-results .no-results {
      width: 15em;
      height: 15em; } }
  .search-results .no-results .no-results-text {
    background-color: rgba(38, 34, 98, 0.7);
    position: absolute;
    margin: 0 0 0 32px;
    bottom: 0;
    padding: 0;
    overflow: hidden; }
    .search-results .no-results .no-results-text .text {
      font-family: 'Zilla Slab Highlight', sans-serif;
      color: white;
      font-size: 2.25em;
      margin: 0;
      padding: 0; }
  .search-results .no-results img {
    float: left;
    margin-right: 1em; }
