.home {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  padding: 0; }
  @media (min-width: 768px) {
    .home {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    .home {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    .home {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    .home {
      max-width: 798px; } }
  @media (min-width: 1000px) {
    .home {
      max-width: 982px; } }
  @media (min-width: 1200px) {
    .home {
      max-width: 1030px; } }
