footer {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  font-size: 0.8em;
  line-height: 1.5em;
  clear: both;
  color: #fff;
  background: #333;
  padding: 2em 1.2em 4em; }
  @media (min-width: 768px) {
    footer {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    footer {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    footer {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    footer {
      display: grid;
      grid-template-columns: 1fr 1fr; } }
  @media print {
    footer {
      display: none; } }
  footer > div {
    margin-top: 1em; }
    @media (min-width: 768px) {
      footer > div {
        margin-top: 0; } }
  footer h3 {
    margin: 0 0 .25em; }
  footer a {
    color: #fff;
    text-decoration: none; }
    footer a:visited {
      color: #fff; }
    footer a:hover {
      color: orange; }
  footer .details {
    margin-top: .5em; }
    footer .details .fa-info-circle {
      margin-right: .5em; }
