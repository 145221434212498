@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.sending {
  font-size: 1.5em;
  display: block;
  text-align: center;
  max-width: 42em;
  padding: 1em 0;
  position: relative; }
  .sending .spinner {
    animation: 2s linear infinite spinner;
    margin-right: .5em; }
