.LearnAikidoDotPoints {
  padding: 4rem 1rem; }
  @media (min-width: 768px) {
    .LearnAikidoDotPoints {
      padding: 4rem 0; } }
  .LearnAikidoDotPoints h2 {
    text-align: center; }
  .LearnAikidoDotPoints ul {
    list-style-type: none;
    max-width: 100%; }
    @media (min-width: 768px) {
      .LearnAikidoDotPoints ul {
        display: grid;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-evenly;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 1rem;
        column-gap: 1rem;
        margin: 1rem; } }
    .LearnAikidoDotPoints ul li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 2rem; }
      .LearnAikidoDotPoints ul li .img {
        background-color: #262262;
        margin: 0 0 .5rem 0;
        width: 20rem;
        height: 20rem;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: luminosity;
        transition: all 500ms ease-in-out; }
        .LearnAikidoDotPoints ul li .img:hover {
          background-color: transparent;
          background-blend-mode: none; }
        @media (min-width: 768px) {
          .LearnAikidoDotPoints ul li .img {
            width: 100%;
            height: 9rem; } }
      .LearnAikidoDotPoints ul li .text {
        font-weight: bold;
        color: #262262;
        font-size: 1.25rem;
        line-height: 3rem;
        text-align: center; }
        @media (min-width: 768px) {
          .LearnAikidoDotPoints ul li .text {
            font-size: 1rem;
            line-height: 2rem; } }
  .LearnAikidoDotPoints .interest-form .button-container {
    text-align: center; }
  .LearnAikidoDotPoints.adults .point-4 .img {
    background-position-x: -36rem;
    background-size: inherit; }
    @media (min-width: 768px) {
      .LearnAikidoDotPoints.adults .point-4 .img {
        background-position-x: center;
        background-size: cover; } }
