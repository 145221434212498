@media (min-width: 768px) {
  .Classes .heading {
    display: flex;
    align-items: center;
    justify-content: space-between; } }

@media (min-width: 768px) {
  .Classes .heading h2 {
    margin-bottom: 0; } }

.Classes .content {
  margin-bottom: 2em; }

.Classes .fa-calendar-check {
  color: green; }

.Classes .class-name {
  font-weight: bold; }

.Classes .name {
  font-weight: bold;
  width: 6em;
  display: block;
  float: left; }
