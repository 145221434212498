nav {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0;
  z-index: 99; }
  @media (min-width: 768px) {
    nav {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    nav {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    nav {
      max-width: 1000px; } }
  @media (min-width: 1000px) {
    nav {
      max-width: 798px;
      height: 2.1em;
      background: #fff;
      position: relative;
      overflow: visible;
      margin: 0 auto; } }
  @media (min-width: 1000px) {
    nav {
      max-width: 982px; } }
  @media (min-width: 1200px) {
    nav {
      max-width: 1030px; } }
  @media print {
    nav {
      display: none; } }
  nav .toggle {
    font-size: 1.25em;
    display: block;
    position: absolute;
    top: 1.4em;
    left: 0;
    z-index: 99;
    color: #262262;
    padding: .5em 1em 1em .5em; }
    @media (min-width: 1000px) {
      nav .toggle {
        display: none; } }
  nav .fa-times {
    margin: -.75em 0 0 .5em;
    color: #fff; }
  nav ul {
    list-style-type: none;
    background: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    width: 100vw;
    height: 300vh;
    margin: 0;
    padding: 3em 0;
    z-index: 50; }
    @media (min-width: 1000px) {
      nav ul {
        position: absolute;
        background: #fff;
        padding: 0;
        height: auto;
        width: 100%;
        overflow: visible; } }
    nav ul.hide {
      display: none; }
      @media (min-width: 1000px) {
        nav ul.hide {
          display: block; } }
    nav ul.show {
      display: block; }
    nav ul li {
      border-bottom: 1px solid #333;
      position: relative; }
      nav ul li a {
        display: block;
        color: #fff;
        text-decoration: none;
        padding: 1em;
        font-weight: bold;
        width: calc(100% - 2em); }
        nav ul li a:visited {
          color: #fff; }
          @media (min-width: 1000px) {
            nav ul li a:visited {
              color: #1f1f1f; } }
        nav ul li a.current {
          color: #262262;
          background: white; }
          @media (min-width: 1000px) {
            nav ul li a.current {
              background: #262262;
              color: white; } }
          nav ul li a.current > ul {
            display: block; }
        @media (min-width: 1000px) {
          nav ul li a {
            color: #1f1f1f;
            padding: .5em 1em; } }
      @media (min-width: 1000px) {
        nav ul li:hover > a {
          background: #262262;
          color: white; } }
      @media (min-width: 1000px) {
        nav ul li:hover ul {
          display: block; }
          nav ul li:hover ul.expand {
            display: block; } }
      nav ul li.flag a img {
        width: 1.5em;
        padding-bottom: 0; }
      nav ul li.flag a:hover {
        background: transparent; }
        nav ul li.flag a:hover img {
          opacity: 0.8; }
      @media (min-width: 768px) {
        nav ul li.parent > a {
          padding: .5em 1em .5em 1.5em; } }
      @media (min-width: 1000px) {
        nav ul li {
          float: left;
          border-bottom: none; } }
      nav ul li ul {
        height: auto;
        padding: 0;
        display: none; }
        nav ul li ul.expand {
          display: block; }
          @media (min-width: 1000px) {
            nav ul li ul.expand {
              display: none; } }
        @media (min-width: 1000px) {
          nav ul li ul {
            position: absolute;
            top: 2.3rem;
            z-index: 199;
            min-width: 15em;
            border: 1px solid #262262; } }
        @media (min-width: 1000px) {
          nav ul li ul > li {
            width: 100%; } }
        nav ul li ul > li a {
          padding-left: 2em; }
          @media (min-width: 1000px) {
            nav ul li ul > li a {
              padding-left: 1em; } }

.chevron {
  color: #fff;
  float: left;
  margin: 1em .5em 0 .5em;
  font-size: 1em; }
  .current .chevron,
  .active .chevron {
    color: #262262; }
    @media (min-width: 1000px) {
      .current .chevron,
      .active .chevron {
        color: #fff; } }
  .parent .chevron.down {
    display: none; }
    @media (min-width: 768px) {
      .parent .chevron.down {
        display: block; } }
  .parent .chevron.right {
    float: left; }
    @media (min-width: 768px) {
      .parent .chevron.right {
        display: none; } }
  .active .chevron.right {
    display: none; }
  .expand .chevron.right {
    display: none; }
  .active .chevron.down {
    display: block; }
  .expand .chevron.down {
    display: block;
    color: #fff; }
  .active.expand .chevron.down {
    display: block;
    color: #262262; }
  @media (min-width: 768px) {
    .chevron {
      color: #262262;
      margin-top: .6em; }
      li:hover .chevron {
        color: #fff; } }
