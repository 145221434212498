ul.pagination {
  list-style-type: none;
  display: block;
  clear: both;
  text-align: center;
  overflow: auto;
  max-width: 42em;
  margin: 2em 0;
  padding: 0; }
  ul.pagination li {
    display: inline-block;
    border-radius: .25em;
    background: #262262;
    padding: .5em 1em;
    margin-right: .5em;
    color: #fff; }
    ul.pagination li.dots, ul.pagination li.next, ul.pagination li.previous {
      background: transparent; }
    ul.pagination li:last-child {
      margin-right: 0; }
