.basket {
  overflow: auto; }
  .cart .basket {
    clear: both; }
  .checkout .basket {
    margin-top: 2em;
    clear: both;
    float: none; }
    @media (min-width: 768px) {
      .checkout .basket {
        margin-top: 0;
        clear: none;
        float: right;
        width: 48%; } }
  .basket .row {
    border: #ccc 1px solid;
    border-bottom: none;
    margin-bottom: 0;
    display: grid;
    grid-template-columns: 3em 1fr 5em;
    align-items: center; }
    .basket .row:last-child {
      border-bottom: #ccc 1px solid; }
    @media (min-width: 768px) {
      .basket .row {
        display: grid;
        grid-template-columns: 4em 4em 1fr 6.55em; } }
    .basket .row div {
      margin-bottom: 0; }
    .basket .row .quantity {
      display: grid;
      grid-template-columns: 1fr 1em;
      grid-template-rows: 50% 50%;
      padding: 0 .5em 0;
      height: 3em; }
      @media (min-width: 768px) {
        .basket .row .quantity {
          height: auto; } }
      .checkout .basket .row .quantity {
        display: block; }
      .basket .row .quantity .value {
        grid-row: 1 / 3;
        grid-column: 1;
        display: flex;
        justify-content: center;
        align-items: center; }
      .basket .row .quantity .alter {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8em;
        grid-column: 2;
        grid-row: 1; }
        .basket .row .quantity .alter.minus {
          grid-row: 2; }
        .basket .row .quantity .alter.pro-rated {
          display: none; }
        .checkout .basket .row .quantity .alter {
          display: none; }
    .basket .row .image {
      display: none; }
      @media (min-width: 768px) {
        .basket .row .image {
          display: flex;
          justify-content: center;
          align-items: center; } }
    .basket .row .details {
      padding: .5em 0 .5em 1em;
      display: block;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc; }
      .basket .row .details span {
        display: block;
        clear: left;
        float: none;
        color: #333; }
        .basket .row .details span.name {
          font-weight: 600; }
        .basket .row .details span.description {
          font-size: 0.8em; }
      @media (min-width: 768px) {
        .basket .row .details {
          border: none; } }
    .basket .row .price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: .5em;
      text-align: right; }
  .basket .totes {
    margin-top: 1em;
    clear: both; }
    @media (min-width: 768px) {
      .basket .totes {
        width: 13.2em;
        float: right; } }
    .basket .totes .total-row {
      border: #ccc 1px solid;
      border-bottom: none;
      margin-bottom: 0;
      clear: both;
      overflow: auto; }
      .basket .totes .total-row .label {
        width: calc(50% - 1em);
        text-align: right;
        display: inline-block;
        background: rgba(204, 204, 204, 0.5);
        padding: .5em;
        margin-right: 0; }
      .basket .totes .total-row .value {
        width: calc(50% - .5em);
        padding-right: .5em;
        text-align: right;
        display: inline-block; }
      .basket .totes .total-row:last-child {
        border-bottom: #ccc 1px solid; }
  .basket .no-gst {
    margin-top: 1em;
    color: #333; }
