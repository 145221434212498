.check-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%; }
  @media (min-width: 768px) {
    .check-in-form .field {
      display: grid;
      grid-template-columns: 12rem 1fr; } }
  .check-in-form .field label {
    grid-column: 1; }
    @media (min-width: 768px) {
      .check-in-form .field label {
        width: auto; } }
  .check-in-form .field input {
    grid-column: 2; }
    @media (min-width: 768px) {
      .check-in-form .field input[type="text"], .check-in-form .field input[type="phone"] {
        width: calc(100% - 1rem); } }
  .check-in-form .field select {
    width: 100%; }
  .check-in-form .field.radio-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    @media (min-width: 768px) {
      .check-in-form .field.radio-buttons {
        display: grid;
        grid-template-columns: 12rem 1fr; } }
    .check-in-form .field.radio-buttons .radio-button {
      display: block;
      grid-column: 2;
      padding: 0;
      margin-right: 0; }
  .check-in-form .field.child-name {
    display: none; }
  .check-in-form.kids .field.child-name {
    display: grid; }

.pay {
  display: flex;
  justify-content: center;
  align-items: center; }
  .pay.bank {
    display: block; }
    .pay.bank ul {
      list-style-type: none; }
  .pay .svg-inline--fa {
    margin-right: .5rem;
    font-size: 3rem;
    color: #333; }
