.product {
  position: relative;
  overflow: auto;
  clear: both;
  padding-bottom: 3em;
  border-bottom: 1px solid #ccc;
  align-items: flex-start; }
  @media (min-width: 768px) {
    .product {
      display: grid;
      grid-template-columns: 15rem 1fr 15rem;
      column-gap: 1rem;
      row-gap: .5rem; } }
  .product > div {
    margin-bottom: 0; }
  .product:last-child {
    border-bottom: none; }
  .product h2 {
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      .product h2 {
        grid-column: 1 / 3;
        grid-row: 1; } }
  .product input[type="number"] {
    width: 2rem;
    margin: 0 .5rem; }
  .product img {
    width: 100%; }
    @media (min-width: 768px) {
      .product img {
        grid-column: 3;
        grid-row: 1 / 5; } }
  .product .description {
    max-width: 42em;
    clear: left; }
    @media (min-width: 768px) {
      .product .description {
        grid-column: 1 / 3;
        grid-row: 3; } }
  @media (min-width: 768px) {
    .product .adjust-weeks {
      grid-column: 1 / 3;
      grid-row: 4; } }
  .product .price {
    margin-right: 1em; }
    @media (min-width: 768px) {
      .product .price {
        grid-column: 1 / 3;
        grid-row: 2; } }
    .product .price .subscription {
      font-size: 0.8em;
      color: #333;
      margin-left: .5em; }
  .product .adjust-weeks {
    background: #faec446b;
    padding: .5rem; }
  .product button {
    position: relative;
    display: block;
    text-align: center; }
    @media (min-width: 768px) {
      .product button {
        display: inline-block;
        margin-right: 1em;
        grid-column: 1;
        grid-row: 4; } }
    .product button:hover, .product button:focus {
      border-color: orange;
      background: #fff;
      outline: none;
      color: orange; }
    .product button.added {
      color: green;
      border-color: green;
      background: transparent; }
    .product button .svg-inline--fa {
      margin-right: .5em; }
  .product .go {
    text-decoration: none;
    display: flex;
    flex-direction: flex-start;
    align-items: center;
    text-align: center;
    color: orange;
    background: #fff;
    border: none; }
    @media (min-width: 768px) {
      .product .go {
        text-align: left;
        display: inline; } }
    .product .go .svg-inline--fa {
      margin-right: .5em; }
  @media (min-width: 768px) {
    .product.kids button {
      grid-row: 5; } }

.sku:before {
  content: "sku:";
  text-transform: uppercase;
  padding-right: .5em;
  color: #333;
  font-size: 0.8em; }
