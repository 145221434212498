label {
  font-size: 1em;
  width: 10em;
  display: inline-block;
  padding: 0.5em 0; }
  @media (min-width: 768px) {
    label {
      grid-row: 1;
      grid-column: 1; } }
  .interest-form label {
    font-size: 1.25em;
    width: 4em; }
  .radio-button label {
    display: block;
    float: left;
    width: auto; }

.required {
  padding-left: 0.25em;
  color: red; }
