.image-heading {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  display: block;
  position: relative;
  height: 100px;
  overflow: hidden; }
  @media (min-width: 768px) {
    .image-heading {
      max-width: 800px; } }
  @media (min-width: 1000px) {
    .image-heading {
      max-width: 981px; } }
  @media (min-width: 1200px) {
    .image-heading {
      max-width: 1032px; } }
  @media (min-width: 768px) {
    .image-heading {
      height: 200px; } }
  @media (min-width: 1000px) {
    .image-heading {
      height: 300px; } }
  @media print {
    .image-heading {
      height: 3em;
      background: #fff; } }
  @media (min-width: 1000px) {
    .image-heading h2:first-of-type {
      margin-top: -.1em; } }
  .image-heading .background-image {
    position: absolute;
    height: 100%;
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover; }
    @media (min-width: 768px) {
      .image-heading .background-image {
        background-position: center center; } }
    @media print {
      .image-heading .background-image {
        display: none; } }

.section-heading {
  background: #fff; }
  @media (min-width: 768px) {
    .section-heading {
      background-color: rgba(38, 34, 98, 0.8);
      position: absolute;
      margin: 0 0 0 32px;
      bottom: -.1em;
      padding: 0;
      overflow: hidden; } }
  @media print {
    .section-heading {
      background: #fff;
      height: 3em;
      margin: 0;
      bottom: 0; } }
  .section-heading h1,
  .section-heading h2 {
    font-family: 'Zilla Slab Highlight', sans-serif;
    color: #262262;
    text-align: center;
    background: transparent;
    text-transform: uppercase;
    font-size: 2.25em;
    width: 100%;
    margin: -.05em 0;
    padding: 0; }
    @media (min-width: 768px) {
      .section-heading h1,
      .section-heading h2 {
        font-size: 3em;
        color: #fff;
        text-align: left; } }
    @media (min-width: 1000px) {
      .section-heading h1,
      .section-heading h2 {
        font-size: 6em; } }
    @media print {
      .section-heading h1,
      .section-heading h2 {
        font-family: 'Open Sans', sans-serif;
        font-size: 2em;
        color: #1f1f1f;
        padding-left: 1em; } }
