.class-details {
  background-size: cover;
  overflow: hidden;
  margin-bottom: 1em;
  clear: both; }
  footer .class-details {
    background: transparent; }
  .class-details img {
    width: 100%;
    margin-bottom: 1rem; }
  .class-details a {
    background: transparent;
    text-decoration: underline;
    color: orange; }
    .class-details a:visited {
      background: transparent;
      text-decoration: underline;
      color: orange; }
    .class-details a:hover {
      background: orange;
      color: #fff;
      text-decoration: none; }
  .class-details > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;
    max-width: unset;
    width: 100%; }
    .class-details > ul li {
      margin: 0 0 1em 1.5em;
      padding: 0;
      position: relative; }
      .class-details > ul li .svg-inline--fa {
        position: absolute;
        left: -1.5em;
        top: .25em;
        color: #262262; }
      .class-details > ul li span {
        margin-left: 0; }
      .class-details > ul li.days ul {
        margin: 0;
        padding: 0;
        list-style-type: none; }
        .class-details > ul li.days ul li {
          margin: 0 0 .5rem;
          padding: 0; }
      .class-details > ul li.content ul li {
        margin-bottom: .5rem; }
      .class-details > ul li.class-name {
        margin-bottom: 1em;
        margin-left: 0; }
        .class-details > ul li.class-name h2 {
          margin: 0 0 .5em; }
        .class-details > ul li.class-name span {
          font-size: 1.25em; }
