form button {
  font-size: 1.25em;
  background: orange;
  padding: .5em 1em;
  border: none;
  border-radius: .25em;
  display: block;
  margin: .5em auto; }
  form button.disabled {
    cursor: not-allowed;
    background: #ccc; }
