header {
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 1em 1em 0;
  height: 7.5em; }
  @media (min-width: 768px) {
    header {
      max-width: 768px; } }
  @media (min-width: 1000px) {
    header {
      max-width: 950px; } }
  @media (min-width: 1200px) {
    header {
      max-width: 1000px; } }
  @media (min-width: 768px) {
    header {
      display: grid;
      grid-template-columns: 18em 1fr 18em;
      grid-template-rows: 1fr 1.5rem;
      margin-bottom: 0;
      height: auto; } }
  @media print {
    header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 0;
      display: block;
      clear: both;
      overflow: auto;
      height: 5em;
      padding: 1em 2em 0;
      max-width: 970px; } }
  header a {
    display: block;
    border: 1px solid #fff; }
    header a:hover, header a:focus {
      outline: none; }
    header a svg {
      max-width: 13em;
      display: block;
      margin: auto; }
      @media (min-width: 768px) {
        header a svg {
          max-width: 18em;
          margin: 0;
          grid-row: 1;
          grid-column: 1; } }
      @media print {
        header a svg {
          max-width: 13em;
          float: left; } }
  header .tagline {
    font-family: 'Zilla Slab Highlight', sans-serif;
    text-transform: uppercase;
    color: orange;
    text-align: center;
    font-size: 1.35rem; }
    @media (min-width: 768px) {
      header .tagline {
        font-size: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0; } }
    @media print {
      header .tagline {
        float: right;
        clear: right;
        font-size: 1.5em;
        margin-top: .5em;
        color: #1f1f1f; } }
  header .location {
    font-family: 'Overpass Mono', monospace;
    text-transform: uppercase;
    font-size: 0.8em;
    text-align: center; }
    @media (min-width: 768px) {
      header .location {
        grid-row: 2;
        grid-column: 1; } }
