.location .contact {
  margin-bottom: 2em; }
  .location .contact h2 {
    display: none; }
  .location .contact h3 {
    margin-top: 0; }

.contact div[itemprop="address"] {
  margin-top: 1em; }

.contact span {
  display: inline-block;
  clear: left; }
  .contact span[itemprop="telephone"] a {
    margin-left: .5em; }
  .contact span[itemprop="streetAddress"] {
    display: block; }
  .contact span[itemprop="postalCode"] {
    margin-right: .5em; }
