.rank {
  position: relative; }
  .rank .heading {
    border-bottom: 1px solid #ccc;
    margin-bottom: 0;
    padding: 0.5em;
    overflow: auto;
    display: grid;
    grid-template-columns: 2em 1fr 8em; }
    @media print {
      .rank .heading {
        background: none;
        grid-template-columns: 1fr 8em;
        padding: 0; } }
    @media print {
      .rank .heading.closed {
        margin-bottom: 1em;
        border-bottom: 2px solid #1f1f1f; } }
    .rank .heading.open {
      margin-bottom: 1em; }
    .rank .heading .svg-inline--fa {
      margin-right: .5em;
      font-size: 1.25em;
      grid-column: 1;
      text-align: center;
      margin: 0; }
      @media print {
        .rank .heading .svg-inline--fa {
          display: none; } }
    .rank .heading h2 {
      margin: 0;
      grid-column: 2; }
      @media print {
        .rank .heading h2 {
          grid-column: 1; } }
    .rank .heading .colour {
      font-family: 'Overpass Mono', monospace;
      text-transform: uppercase;
      font-size: 0.8em;
      grid-column: 3;
      color: #fff;
      margin: 0;
      padding: 0.25em .5em;
      border-radius: .25em;
      background: #333;
      width: 90%;
      text-align: center; }
      .rank .heading .colour.brown {
        background: brown; }
      .rank .heading .colour.purple {
        background: purple; }
      .rank .heading .colour.red {
        background: red; }
      .rank .heading .colour.green {
        background: green; }
      .rank .heading .colour.blue {
        background: #262262; }
      .rank .heading .colour.orange {
        background: orange; }
      .rank .heading .colour.yellow {
        background: yellow;
        color: #1f1f1f; }
      @media print {
        .rank .heading .colour {
          grid-column: 2;
          color: #1f1f1f;
          background: none !important;
          text-align: right;
          font-size: 1em; }
          .rank .heading .colour:after {
            content: ' belt'; } }
  .rank .hide {
    display: none; }
    @media print {
      .rank .hide {
        display: block; } }
  @media print {
    .rank .techniques {
      page-break-after: always; } }
  .rank .techniques .group {
    border-bottom: 1px solid #ccc;
    padding: .5em 0;
    margin-bottom: 0; }
    .rank .techniques .group:last-child {
      border-bottom: none; }
    @media (min-width: 768px) {
      .rank .techniques .group {
        display: grid;
        grid-template-columns: 25% 1fr; }
        .rank .techniques .group.grade {
          grid-template-columns: 25% 1fr 15em; } }
    @media print {
      .rank .techniques .group {
        display: grid;
        grid-template-columns: 30% 1fr; } }
    .rank .techniques .group div {
      margin-bottom: 0; }
    .rank .techniques .group .label {
      grid-column: 1;
      font-size: 1em;
      margin-bottom: 0; }
    .rank .techniques .group .content {
      grid-column: 2; }
  .rank .summary {
    position: fixed;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    width: calc(100% - 2em); }
    @media (min-width: 1000px) {
      .rank .summary {
        width: calc(1000px - 1.8em); } }
    .rank .summary > div {
      margin-bottom: 0; }
    .rank .summary .total {
      font-family: 'Overpass Mono', monospace;
      text-transform: uppercase;
      font-size: 0.8em;
      font-size: 1.5em;
      line-height: 1.25em;
      font-weight: bold;
      width: 100%;
      text-align: center; }
      .rank .summary .total.pass {
        color: green; }
      .rank .summary .total.fail {
        color: red; }
    .rank .summary .unscored,
    .rank .summary .max {
      text-align: center; }
  .rank .score {
    font-family: 'Overpass Mono', monospace;
    text-transform: uppercase;
    font-size: 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1em; }
    @media (min-width: 1000px) {
      .rank .score {
        margin-top: 0; } }
    .rank .score .number {
      font-family: 'Overpass Mono', monospace;
      text-transform: uppercase;
      font-size: 0.8em;
      font-size: 1.2em;
      padding: .25em 1em;
      border: 1px solid #ccc;
      border-radius: .25em;
      background: #ccc;
      font-weight: bold; }
      .rank .score .number.selected {
        background: green; }
        .rank .score .number.selected.five {
          color: white; }
        .rank .score .number.selected.four {
          background: #00e600; }
        .rank .score .number.selected.three {
          background: orange; }
        .rank .score .number.selected.two {
          background: #ff6666; }
        .rank .score .number.selected.one {
          background: red; }
  .rank .comment {
    font-family: 'Overpass Mono', monospace;
    text-transform: uppercase;
    font-size: 0.8em;
    text-align: center; }
    .rank .comment.no-grade {
      color: red; }
