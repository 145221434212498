.search-form {
  overflow: auto; }
  @media (min-width: 768px) {
    header .search-form {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      grid-column: 3; } }
  @media print {
    header .search-form {
      display: none; } }
  @media (min-width: 768px) {
    header .search-form form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      width: 20em;
      margin-right: 3em; } }
  @media (min-width: 1000px) {
    header .search-form form {
      margin-right: 0; } }
  .search-form label {
    display: none; }
  .search-form input {
    padding: .5em;
    width: calc(100% - 8em);
    border: 1px solid #ccc;
    float: left;
    font-size: .925em; }
    @media (min-width: 768px) {
      .search-form input {
        display: block;
        width: calc(100% - 6em);
        border: 1px solid #ccc;
        margin: 1.4em 0 0 0; } }
    .search-form input.show {
      display: block; }
    header .search-form input {
      display: none;
      padding: .5em;
      width: calc(100% - 2em);
      margin: 0; }
      @media (min-width: 768px) {
        header .search-form input {
          display: block;
          width: calc(100% - 7em);
          float: left;
          border: 1px solid #ccc;
          margin: 0; } }
    section .search-form input {
      margin-top: 0;
      width: calc(100% - 4em); }
  .search-form button {
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0;
    padding: .67em 1em;
    position: relative;
    background: transparent;
    float: left;
    margin: 0;
    right: 0;
    font-size: 11px; }
    @media (min-width: 768px) {
      .search-form button {
        border: 1px solid #ccc;
        border-left: none;
        border-radius: 0;
        padding: .67em 1em;
        margin-top: 1.4em; } }
    header .search-form button {
      position: absolute;
      background: transparent;
      border: none;
      top: 1.5em;
      padding: .5em .75em 1em 1em; }
      @media (min-width: 768px) {
        header .search-form button {
          border: 1px solid #ccc;
          border-left: none;
          border-radius: 0;
          padding: .67em 1em;
          position: relative;
          top: inherit;
          margin: 0; } }
    section .search-form button {
      position: relative;
      float: left;
      margin: 0;
      padding: .7em 1em; }
      @media (min-width: 768px) {
        section .search-form button {
          padding: .67em 1em; } }
    .search-form button .search-icon {
      font-size: 1.75em; }
    .search-form button .search-text {
      display: none; }
  .search-form.mobile-show {
    position: relative;
    margin: 0 auto;
    width: 55%;
    margin-bottom: 1em; }
    @media (min-width: 768px) {
      .search-form.mobile-show {
        width: 70%;
        margin: 1.2em 0 0 auto; } }
    .search-form.mobile-show input {
      margin-top: 0;
      width: calc(100% - 4em); }
    .search-form.mobile-show button {
      border: 1px solid #ccc;
      border-left: none;
      border-radius: 0;
      padding: .67em 1em;
      top: 0;
      margin: 0;
      padding: .65em 1em; }
    section .search-form.mobile-show {
      margin: 1em 0; }
      @media (min-width: 768px) {
        section .search-form.mobile-show {
          display: block;
          width: 50%;
          margin: 0; } }
  section .search-form {
    width: 100%; }
    @media (min-width: 768px) {
      section .search-form {
        width: 32em; } }
