$grey-dark: #333;
$grey-light: #ccc;
$white: #fff;
$black: #1f1f1f;
$blue-dark: #262262;
$green: green;
$orange: orange;
$red: red;
$yellow: #f8f8b4;

$dark-background: rgba(0,0,0, 0.90);