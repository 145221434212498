.radio-buttons input {
  display: none; }

.radio-buttons label {
  margin-left: .5em; }
  .interest-form .radio-buttons label {
    width: auto; }

.radio-buttons .radio-button {
  display: inline-block;
  margin-right: 1em;
  padding: .5em; }

.radio-buttons .circle {
  display: block;
  float: left;
  width: 1.05em;
  height: 1.05em;
  border-radius: 1em;
  margin: .4em .1em 0 0;
  border: 2px solid #262262; }

.radio-buttons .fa-check-circle {
  display: block;
  float: left;
  font-size: 1.4em;
  margin: .25em 0 0;
  color: green; }
